import Card from "../../../../components/UI/Card";
import {useState} from "react";
import SizeListActions from "../../../../views/products/settings/sizes/SizeListActions";
import SizeForm from "../../../../views/products/settings/sizes/SizeForm";
import {getSizes} from "../../../../api/sizes";
import {createColumnHelper} from "@tanstack/react-table";
import Table from "../../../../components/UI/Table";
import SizeTableActions from "../../../../views/products/settings/sizes/SizeTableActions";
import SizeTableActiveComponent from "../../../../views/products/settings/sizes/SizeTableActiveComponent";
import SizeTableDefaultComponent from "../../../../views/products/settings/sizes/SizeTableDefaultComponent";
const SizeList = ()=> {
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: 'Name'
    }),

    columnHelper.accessor('size', {
      id: 'size',
      header: 'Size'
    }),

    columnHelper.display( {
      id: 'active',
      header: "Active",
      cell: props => <SizeTableActiveComponent id={props.row.original.id}
                                               active={props.row.original.status} />
    }),

    columnHelper.display( {
      id: 'default',
      header: "Default",
      cell: props => <SizeTableDefaultComponent id={props.row.original.id}
                                               active={props.row.original.default} />
    }),

    columnHelper.display( {
      id: 'actions',
      header: "Actions",
      classes:'text_right',
      cell: props => <SizeTableActions editSize={editHandler} row={props.row.original} />
    }),
  ];


  const [size, setEditSize] = useState('')
  const [openModal, setOpenModal] = useState(false)


  const editHandler = (size) =>{
    setEditSize(size)
    setOpenModal(true)
  }

  const addNewSize = () =>{
    setEditSize(null)
    setOpenModal(true)
  }

  return (
    <>
      <Card title={"Sizes"}  CardActions={<SizeListActions clickHandler={addNewSize}/>}>
        <Table
          queryKey={"sizes"}
          columns={columns}
          queryParams={{per_page: 10}}
          queryFn={getSizes}
        />
      </Card>
      <SizeForm size={size} openModal={openModal} setOpenModal={setOpenModal} />
    </>
  )
}

export default SizeList;