import Card from "../../components/UI/Card";
import {getVendors} from "../../api/vendor";
import {createColumnHelper} from "@tanstack/react-table";
import Table from "../../components/UI/Table";
import VendorListAction from "../../views/vendors/VendorListAction";
import {useState} from "react";
import VendorFilterComponent from "../../views/vendors/VendorFilterComponent";

const Vendor = () => {
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('name', {
      id: "name",
      header: "Company Name"
    }),
    columnHelper.accessor('legal_business_name', {
      id: "legal_business_name",
      header: "Business Name"
    }),
    columnHelper.accessor('email', {
      id: "email",
      header: "Email"
    }),
    columnHelper.display({
      id: "commission",
      header: "Commission",
      cell: props => <span>{props.row.original.commission ? props.row.original.commission : 0 }%</span>,
    }),
    columnHelper.accessor('phone', {
      id: "phone",
      header: "Phone"
    }),
    columnHelper.accessor('city', {
      id: "city",
      header: "City"
    }),

    columnHelper.accessor('created_at', {
      id: "created_at",
      header: "Created At"
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      classes: 'text_right',
      cell: props => <VendorListAction row={props.row.original} />,
    }),
  ];

  const [params, setParams] = useState({
    per_page: 50,
    search: '',
    page:1
  })


  return(
    <>
      <Card title="Vendors">
        <VendorFilterComponent searchHandler={setParams} params={params} />
        <Table queryKey={`vendors`}
               columns={columns}
               queryParams={{...params}}
               queryFn={getVendors} />
      </Card>
    </>
  )
}

export default Vendor;