import React from "react";
import { useQuery} from "@tanstack/react-query";
import {getProducts} from "../../../../api/products";
import ProductComponent from "./includes/ProductComponent";
import Pagination from "../../../../components/UI/Pagination";
import {setParams, setSendRequest} from "../../../../store/slices/productSlice";
import {useDispatch, useSelector} from "react-redux";


const ItemListComponent = () => {
  const dispatch = useDispatch();
  const store = useSelector(state => state.product)

  const { data } = useQuery({
    queryKey: ['categories'],
    queryFn:()=> getProducts(),
    initialData: () => []
  });


  const changePage = (page) => {
    dispatch(setParams({...store.initialParams, page}))
    dispatch(setSendRequest(true))
  }

  return (
    <>
      <div className="item_list_wrapper">
        <div className="item_list_wrap">
          {store.allProducts.map((product, index) => (
            <ProductComponent product={product} key={index}/>
          ))}
        </div>
        {(store.paginations && store.paginations.per_page < store.paginations.total) ?
          <Pagination meta={store.paginations} changePageFn={changePage} isPlaceholderData={false}/> : ''}
      </div>

    </>
  );
}
export default ItemListComponent;