import Button from "../../../../components/UI/Button";

const ColorListActions = ({clickHandler}) => {
  return (
    <Button
      text="+ Add New Made In"
      variant={"btn_primary"}
      onClickHandler={clickHandler}
    />
  );
};

export default ColorListActions;