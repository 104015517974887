import Card from "../../../../components/UI/Card";
import {useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getMasterColors} from "../../../../api/masterColors";
import MasterColorItem from "../../../../views/products/settings/master-colors/MasterColorItem";
import MasterColorListActions from "../../../../views/products/settings/master-colors/MasterColorListActions";
import MasterColorForm from "../../../../views/products/settings/master-colors/MasterColorForm";

const MasterColorList = () => {
  const [openModal, setOpenModal] = useState(false)
  const [color, setColor] = useState(null)

  const { data: colors } = useQuery({
    queryKey: ['master-colors'],
    queryFn:()=> getMasterColors({page:1,per_page:10})
  });

  const editHandler = (id) =>{
    const color = colors.find(item => item.id === id)
    setColor(color);
    setOpenModal(true);
  }

  const addNewColor = () =>{
    setColor(null);
    setOpenModal(true);
  }
  return (
    <>
      <Card title={"Master Colors"}  CardActions={<MasterColorListActions clickHandler={addNewColor}/>}>
        <div className="item_color_main_wrap">
          <div className="item_color_list_wrap">
            <div className="item_color_wrap">
              {(colors && colors.length)  &&
              colors.map((color)=>(
                <MasterColorItem color={color} key={color.id} handleClick={editHandler} />
              ))
              }
            </div>
          </div>
        </div>
      </Card>

      <MasterColorForm color={color}
                 openModal={openModal}
                 setOpenModal={setOpenModal} />
    </>
  )
}

export default MasterColorList;