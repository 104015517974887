import Button from "../../../../components/UI/Button";

const SizeListActions = ({clickHandler}) => {
  return (
    <Button
      text="+ Add New Size"
      variant={"btn_primary"}
      onClickHandler={clickHandler}
    />
  );
}

export default SizeListActions;