import Button from "../../components/UI/Button";
import {useEffect, useState} from "react";

export const VendorFilterComponent = ({searchHandler, params})=>{
  const [filterParams, setFilterParams] = useState({...params});

  const handleInputChange = (event) => {
    setFilterParams((prevParams) => ({
      ...prevParams,
      search: event.target.value,
      page: 1,
    }));

  }
  const changePerPage = (event) => {
    setFilterParams((prevParams) => ({
      ...prevParams,
      per_page: event.target.value,
      page: 1
    }));
    searchHandler({...filterParams, per_page:event.target.value, page: 1})
  }
  const sendSearchRequest = ()=>{
    searchHandler(filterParams)
  }


  const resetSearch = ()=>{
    searchHandler({
      per_page: 50,
      page: 1,
      search: ''
    })
    setFilterParams({
      per_page: 50,
      page: 1,
      search: ''
    })
  }

  const enterPressHandler = (e) => {
    if(e.key === 'Enter'){
      searchHandler(filterParams)
    }
  }

  return (
    <>
      <div className="color_filter_content pt_15">

        <div className="row">
          <div className="col_6">
            <div className="form_row">
              <div className="form_input">
                <div className="search_input">
                  <input type="text" className="form_global"
                         value={filterParams? filterParams.search : ''}
                         onKeyPress={enterPressHandler}
                         placeholder={'Search by name, email, phone, city or business name'}
                         onChange={(event)=>handleInputChange(event)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col_3">
            <Button text={"Search"}
                    variant={"btn_info"}
                    classes={'mr_10 height_40p'}
                    onClickHandler={sendSearchRequest} />

            <Button text={"Reset"}
                    variant={"btn_primary height_40p"}
                    onClickHandler={resetSearch} />
          </div>


          <div className="col_3">
            <div className="d_flex_end">
              <select className="form_global max_width_200p" value={params.per_page} onChange={(event)=>{changePerPage(event)}}>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="80">80</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VendorFilterComponent