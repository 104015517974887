import API from "../util/api";

export const getBanners = async (params) => {
  let data = null;

  await API.get('/banners', {params})
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const addBanners = async (payload) => {
  let data = null;

  await API.post('/banners', payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const updateBanners = async (id, payload) => {
  let data = null;
  payload.append('_method','PATCH')

  await API.post(`/banners/${id}`, payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const deleteBanner = async (params) => {
  let data = null;

  await API.delete('/banners/'+params.id)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const sortBanner = async (payload) => {
  let data = null;

  await API.post('/banners/sort', payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}