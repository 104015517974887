import API from "../util/api";

export const getPageSections = async (id) => {
  let data = null;

  await API.get(`/page-sections`)
    .then(res => {
      data = res.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const getPageSection = async (id) => {
  let data = null;

  await API.get(`/page-sections/${id}`)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const updateSections = async (id, payload) => {
  let data = null;

  await API.patch(`/page-sections/${id}`, payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const createPageSection = async (payload) => {
  let data = null;

  await API.post(`/page-sections`, payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const updateSectionsProducts = async (id, payload) => {
  let data = null;

  await API.post(`/page-sections/product-add/${id}`, payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const removeSectionsProducts = async (id, payload) => {
  let data = null;

  await API.post(`/page-sections/product-remove/${id}`, payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const deletePageSection = async (params) => {
  let data = null;

  await API.delete('/page-sections/'+params.id)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const sortPageSectionProducts = async (payload) => {
  let data = null;

  await API.post('/page-sections/sort', payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}