import {getErrorObj, getValidationMessage} from "../../../util/validationMessage";

const Input = ({ inline = false,
                 label = "",
                 name,
                 register,
                 validationRules = {},
                 errors =null,
                 type = 'text',
                 classes = ''}) => {

  return (
    <div className={`${ classes } ${inline ? ' form_row_inline' : 'form_row'} ${getErrorObj(errors, name) && 'has_error'}`}>
      <label className={validationRules.required ? 'required' : ''}>{label}</label>
      <div className="form_input">
        <input {...register(name, validationRules)}
               type={type}
               className="form_global" />
      </div>

      {getErrorObj(errors, name) && <small>{getValidationMessage(getErrorObj(errors, name), label)}</small>}
    </div>
  );
};

export default Input;