import Button from "../../components/UI/Button";
const PageSectionCardButton = ({ section,  clickHandler, openAllProducts}) => {
  return (
    <div>
      {section &&
      <Button text={"Show Product List"} classes={'mr_10'} onClickHandler={() => openAllProducts(true)}/>
      }
      <Button
        text={`${section && (section.title || section.description) ? "Edit" : "Add Content"}`}
        variant={"btn_success"}
        onClickHandler={clickHandler}
      />
    </div>
  );
}

export default PageSectionCardButton