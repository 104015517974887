import Button from "../../../../components/UI/Button";
import Modal from "../../../../components/UI/Modal";
import {useDispatch, useSelector} from "react-redux";
import {useMutation} from "@tanstack/react-query";
import {getProducts} from "../../../../api/products";
import {
  setAllProducts,
  setParams,
  setProductPagination,
  setRequestStatus,
  setSendRequest
} from "../../../../store/slices/productSlice";
import React, {useEffect, useState} from "react";
import Pagination from "../../../../components/UI/Pagination";
import SingleProductItem from "./SingleProductItem";
import {
  addOrderFormProduct,  updateOrderFormProduct
} from "../../../../store/slices/orderSlice";

const ProductList = ({setStatus, status, setValue, register, errors, getValues, control}) => {
  const dispatch = useDispatch();
  const store = useSelector(state => state.product);
  const {products} = useSelector(state => state.orders.orderForm);
  const [currentProduct, setCurrentProduct] = useState(null)
  const [combinationModalStatus, setCombinationModalStatus] = useState(false)
  const {mutate:getProductMutate, isPending} = useMutation({
    mutationFn: (variables) => getProducts({...store.initialParams, status: store.activeTab}),
    onSuccess: (response, payload) => {
      dispatch(setAllProducts(response.data))
      dispatch(setProductPagination(response.meta))
    }
  })


  const combinationObject = {product_id: "", quantity: 1, variant: "", product_name: "", product_variant_values: null, id: "", url:"", price: 0, total: 0}

  useEffect(()=>{
    if(store.sendRequest) {
      getProductMutate()
    }
  },[store.sendRequest])

  useEffect(()=>{
    getProductMutate()
  },[])
  useEffect(()=>{
    if(currentProduct) {
      setCombination(rows => ({...rows, product_id: currentProduct.id}))
    }
  },[currentProduct])

  useEffect(()=>{
    if(!isPending)
      dispatch(setSendRequest(false))

    dispatch(setRequestStatus(isPending))
  },[isPending])

  const changePage = (page) => {
    dispatch(setParams({...store.initialParams, page}))
    dispatch(setSendRequest(true))
  }
  const setSelectedItem = (item) => {
    setCurrentProduct(item)
    setCombinationModalStatus(true)
  }

  const [combination, setCombination] = useState({...combinationObject})
  const selectCombination = (inventory) => {
    setCombination(rows => ({...rows, product_variant_values: inventory.variant_combination ? JSON.stringify(inventory.variant_combination) : null, variant: inventory.sku}))
  }

  const confirmCombination = () => {
    let findItem = products.find(item => (+item.product_id) ===  (+combination.product_id))
    let index = products.findIndex(item => (+item.product_id) ===  (+combination.product_id))

    if(index != -1){
      if(findItem) {
        let quantity = (+findItem.quantity) + (+combination.quantity)
        dispatch(updateOrderFormProduct({...findItem, quantity: quantity, total:quantity * currentProduct.price,  product_variant_values:  combination.product_variant_values }))
      }
    } else {
      dispatch(addOrderFormProduct({
        ...combination,
        product_name: currentProduct.name,
        product_id: currentProduct.id,
        total: combination.quantity * currentProduct.price,
        price: combination.price ? combination.price : currentProduct.price,
        product_variant_values: combination.product_variant_values,
        url: currentProduct.media.length ? currentProduct.media[0].url
          : ''}))
    }

    setTimeout(()=>{
      setCombination({...combinationObject})
      setCombinationModalStatus(false)
      setCurrentProduct(null)
    }, 500)

  }

  return (
    <>
      <Modal title={`Product List`} open={status} setOpen={setStatus} size={"modal_1080p"}>
        <div className="order_product_list d_flex_inline width_full">
          <div className="row width_full">
            {store.allProducts.map((product, index) => (
              <SingleProductItem product={product} key={index} setSelectedSingleItem={setSelectedItem}/>
            ))}
          </div>
        </div>
        {(store.paginations && store.paginations.per_page < store.paginations.total) ?
          <Pagination meta={store.paginations} changePageFn={changePage} isPlaceholderData={false}/> : ''}
      </Modal>

      <Modal title={`${currentProduct ? currentProduct.sku : ''} Combinations`} open={combinationModalStatus} setOpen={setCombinationModalStatus} size={"modal_500p"}>
        <div className="order_product_list d_flex_inline width_full">
          <div className="row width_full">
            <div className="col_12">
              <div className="form_row">
                <div className="form_input">
                  <input value={combination.quantity}
                         type="number"
                         className="form_global"
                         onChange={(e)=>setCombination(rows => ({...rows, quantity: +e.target.value}))}
                         placeholder="quantity" />
                </div>
              </div>
              {(currentProduct && currentProduct.inventories.length) ?
                <div className="table_responsive">
                  <table className={"table combination_table"}>
                    <thead>
                    <tr>
                      <th><b>Combination</b></th>
                      <th><b>SKU</b></th>
                      <th className={"text_right width_80p"}><b>Price</b></th>
                    </tr>
                    </thead>
                    <tbody>
                    {currentProduct.inventories.map((inventory, i)=>(
                      <tr className={`cursor_pointer ${combination.product_variant_values ===  JSON.stringify(inventory.product_variant_values) ? 'selected' : ''}`}
                          key={'inv_'+i}
                          onClick={()=>selectCombination(inventory)}>
                        <td>{inventory.variant_combination.map((combination)=>  (
                            <span key={'com_'+combination.name}>
                                { combination.value }:
                              { combination.name }
                            </span>
                          )
                        )} </td>
                        <td>{inventory.sku ? inventory.sku : currentProduct.sku}</td>
                        <td className={"text_right width_80p"}>${inventory.price ? inventory.price : currentProduct.price}</td>
                      </tr>))}
                    </tbody>
                  </table>
                </div> : ''}
            </div>
            <div className="col_12 d_flex_end">
              <Button onClickHandler={confirmCombination} text={"OK"} />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ProductList