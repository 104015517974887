import {useState} from "react";
import {createColumnHelper} from "@tanstack/react-table";
import Table from "../../components/UI/Table";
import Card from "../../components/UI/Card";
import {getAllPage} from "../../api/page";
import PageCardActions from "../../views/page/PageCardActions";
import PageTableActions from "../../views/page/PageTableActions";


const PageComponent = () => {
  const [openModal, setOpenModal] = useState(false)
  const [page, setPage] = useState(null)

  const editHandler = (row) =>{
    setPage(row)
    setOpenModal(true)
  }

  const AddNewPage = () =>{
    setPage(null);
    setOpenModal(true);
  }

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('title_en', {
      id: 'title_en',
      header: 'Title En'
    }),
    columnHelper.accessor('title_ko', {
      id: 'title_ko',
      header: 'Title Ko'
    }),
    columnHelper.accessor('slug', {
      id: 'slug',
      header: 'Slug'
    }),
    columnHelper.accessor('created_at', {
      id: 'created_at',
      header: 'Created At'
    }),


    columnHelper.display( {
      id: 'actions',
      header: "Actions",
      classes:'text_right',
      cell: props => <PageTableActions editRecord={editHandler} row={props.row.original} />
    }),
  ];

  return (
    <>
      <Card title={"Pages"}  CardActions={<PageCardActions clickHandler={AddNewPage}/>}>
        <Table
          queryKey={"pages"}
          columns={columns}
          queryParams={{per_page: 10}}
          queryFn={getAllPage}
        />
      </Card>

    </>
  )
}

export default PageComponent