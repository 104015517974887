import {getPage} from "../../api/page";
import {useLoaderData, useLocation, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Card from "../../components/UI/Card";
import PageFormComponent from "../../views/page/PageFormComponent";

export async function loadPage({ params }) {
  const pageData = params.slug ? await getPage(params.slug) : undefined
  return {pageData}
}

const PageComponent = () => {
  const params = useParams();
  const location = useLocation();
  const {pageData} = useLoaderData();
  const [page, setPage] = useState(null);

  useEffect(()=>{
    if(pageData){
      setPage(pageData)
    }
  }, [pageData])

  useEffect(()=>{
    if(location.pathname === '/pages/new'){
      setPage(null)
    }
  }, [params])



  return (
    <Card title={`${page && page.title_en ? page.title_en : "Create New Page" }`}>
      <PageFormComponent page={page} />
    </Card>
  );
}

export default PageComponent;