import Button from "../../components/UI/Button";
import {Link} from "react-router-dom";

const SectionCardActions = () => {
  return(
    <Link to={'/page-sections/create'}>
      <Button text={'New Section'} />
    </Link>
  );
}

export default SectionCardActions