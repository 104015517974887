import API from "../util/api";

export const getCountries = async () => {
    let data = null;

    await API.get('/countries')
        .then(res => {
            data = res.data.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
};