import API from "../util/api";

export const getCategories = async (params) => {
  let data = null;

  await API.get('/categories', {params})
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const deleteCategory = async (params) => {
  let data = null;

  await API.delete('/categories/'+params.id)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const storeCategory = async (payload) => {
  let data = null;

  await API.post('/categories', payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const sortCategory = async (payload) => {
  let data = null;
  await API.post('/categories/sort', payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const updateCategory = async (id, payload) => {
  let data = null;
  payload.append('_method','PATCH')
  await API.post(`/categories/${id}`, payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}