import React, {useEffect} from "react";
import ProductFilterComponent from "../../views/products/product/List/includes/ProductFilterComponent";
import ItemListComponent from "../../views/products/product/List/ItemListComponent";
import {useDispatch, useSelector} from "react-redux";
import {
  setAllProducts,
  setProductPagination, setSendRequest, setRequestStatus
} from "../../store/slices/productSlice";
import {useMutation} from "@tanstack/react-query";
import {getProducts} from "../../api/products";
import PreloaderComponent from "../../components/UI/PreloaderComponent";
import SelectedProductAddSectionsButton from "./SelectedProductAddSectionsButton";
import Modal from "../../components/UI/Modal";


const AllProductsComponent = ({section, products, setProducts, setModalStatus, modalStatus})=>{
  const dispatch = useDispatch();
  const { sendRequest, requestStatus, activeTab, initialParams} = useSelector(state => state.product);


  const {mutate:getProductMutate, isPending} = useMutation({
    mutationFn: (variables) => getProducts({...initialParams, status: activeTab}),
    onSuccess: (response, payload) => {
      dispatch(setAllProducts(response.data))
      dispatch(setProductPagination(response.meta))
    }
  })


  useEffect(()=>{
    if(sendRequest) {
      getProductMutate()
    }
  },[sendRequest])

  useEffect(()=>{
    getProductMutate()
  },[])

  useEffect(()=>{
    if(!isPending)
      dispatch(setSendRequest(false))

    dispatch(setRequestStatus(isPending))
  },[isPending])

  return (
    <Modal title={`Section`} open={modalStatus} setOpen={setModalStatus} size={"modal_1200p"}>
      {requestStatus ? <PreloaderComponent /> : '' }

      <ProductFilterComponent />

      <SelectedProductAddSectionsButton products={products} section={section} setProducts={setProducts} setModalStatus={setModalStatus} />

      <div className={"p_relative"}>
        <ItemListComponent />
      </div>
    </Modal>
  );
}

export default AllProductsComponent;