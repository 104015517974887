import SearchCustomer from "../../views/orders/form-ui/customer-info/SearchCustomer";
import {useSelector} from "react-redux";
import {useEffect} from "react";
import {useMutation} from "@tanstack/react-query";
import {getCustomerDetails} from "../../api/customers";
import {setOrderForm} from "../../store/slices/orderSlice";
import {useDispatch} from "react-redux";
import FormComponent from "../../views/orders/form-ui/FormComponent";
import {getOrderDetails} from "../../api/orders";
import {useLoaderData, useLocation} from "react-router-dom";

export async function loaderOrderDetails({params}){
    const [orderDetails] = await Promise.all([
      getOrderDetails(params.id)
    ]);
    return {orderDetails};
}

const OrderForm = ()=>{
  const {orderForm} = useSelector(state => state.orders)
  const location = useLocation();
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setOrderForm({customer:null, products:[]}))
  }, [location]);
  //For Edit order
  let detailsNew = null;
  const loaderData = useLoaderData();
  if(loaderData)
    detailsNew = loaderData.orderDetails.data

  useEffect(()=>{
    if(detailsNew) {
      dispatch(setOrderForm(detailsNew))
    }
  }, [detailsNew])
  //For Edit order

  const {mutate:details} = useMutation({
    mutationFn: (params) => getCustomerDetails(11),
    onSuccess: (data) => {
      dispatch(setOrderForm({customer:data, products:[]}))
    }
  })



  useEffect(()=>{
    if(!orderForm.customer){
      // details()
    }
  }, [orderForm.customer])

  return (
    <>
      <FormComponent/>
      {/*{orderForm && orderForm.customer ? <FormComponent/> :  <SearchCustomer />}*/}
    </>
  );
}

export default OrderForm