import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../../../util/validationMessage";
import Modal from "../../../../components/UI/Modal";
import Select from "../../../../components/UI/form/Select";
import RadioBox from "../../../../components/UI/form/RadioBox";
import Input from "../../../../components/UI/form/Input";
import Button from "../../../../components/UI/Button";
import {getCategories, storeCategory, updateCategory} from "../../../../api/categories";
import TextArea from "../../../../components/UI/form/TextArea";
import {findObjectById} from "../../../../util/helper";

const CategoryForm = ({category = null, openModal, setOpenModal}) =>{
  const {getValues, setValue, watch, reset, register, setError, clearErrors, handleSubmit, formState: { errors }} = useForm({
    defaultValues: {
      status: "1",
      sort: "1",
    },
  });

  useEffect(() => {
    setValue('second_parent_id', '');
    if(categories && categories.length) {
      const category = categories.find(item => item.id === +getValues('parent_id'))

      if (category) {
        setSubCategories(category.sub_categories || []);
      }
    }
  }, [watch('parent_id')]);

  const {data: categories} = useQuery({
    queryKey:['categories'],
    queryFn: () => getCategories(),
    initialData: () => []
  })

  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    reset();

    if (category) {
      Object.keys(category).forEach(key => {
        if (key === 'status' ) {
          setValue(key, category[key] ? "1" : "0");
        } else {
          setValue(key, category[key]);
        }
      });

      if (category.parent_id) {
        const parentCategory = findObjectById(categories, +category.parent_id, 'sub_categories')

        if (parentCategory) {
          if (parentCategory.parent_id) {
            setValue('parent_id', parentCategory.parent_id);

            setTimeout(() => {
              setValue('second_parent_id', category.parent_id);
            }, 50);
          } else {
            setValue('parent_id', parentCategory.id);
          }
        }
      }
    }
  }, [openModal, category]);

  const queryClient = useQueryClient();
  const [toastId, setToastId] = useState(null);

  const {isPending, mutate} = useMutation({
    mutationFn: category ? (data) => updateCategory(category.id, data) : storeCategory,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      toast.update(toastId, {
        render: `Category ${category ? 'Updated' : 'Added'}`,
        type: "success",
        isLoading: false,
        autoClose: true
      });

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'categories',
      });
      setOpenModal(false)
      reset();
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);
        errorMsg = error.message.message;
      }

      toast.update(toastId, {
        render: errorMsg,
        type: "error",
        isLoading: false,
        autoClose: true
      });
    }
  });

  const onSubmit = (data) => {
    clearErrors();
    const file = data.file[0];
    let formData = new FormData()
    Object.keys(data).forEach(key => {
      formData.append(key, data[key] ? data[key] : '');
    })


    if(file)
      formData.append('file', file)

    mutate(formData)
  }

  return(
    <>
      <Modal title={`${category ? 'Edit' : 'Add'} Category`} open={openModal} setOpen={setOpenModal}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col_12 pr_20">
              <Input label="Name"
                     name="name"
                     errors={errors}
                     validationRules={{required: true, type: 'string'}}
                     register={register}/>
            </div>
          </div>
          <div className="row">
            <div className="col_6 pr_20">
              <Select
                label="Parent Category"
                name="parent_id"
                options={categories || []}
                errors={errors}
                validationRules={{required: false}}
                register={register}/>
            </div>

            <div className="col_6 pr_20">
              <Select
                label="Second Parent Category"
                name="second_parent_id"
                options={subCategories}
                errors={errors}
                validationRules={{required: false}}
                register={register}/>
            </div>
          </div>
          <div className="row">
            <div className="col_6 pr_20">
              <span className={"p_absolute r_10 color_danger"}>Recommended ratio (24:5) e.g: (1920X400)</span>
              <Input label="Image"
                     name="file"
                     type={"file"}
                     errors={errors}
                     register={register}/>
            </div>
            {(category && category.banner) &&
            <div className="col_6 pt_20">
              <img src={category.banner} height={"60"} alt=""/>
            </div>}
          </div>
          <div className="row">
            <div className="col_12 pr_20">
              <TextArea label="Description"
                        name="description"
                        errors={errors}
                        validationRules={{required: false, type: 'string'}}
                        register={register}/>
            </div>
          </div>
          <div className="row">
            <div className="col_6 pr_20">
              <Input label="Meta Title"
                     name="meta_title"
                     errors={errors}
                     validationRules={{required: false, type: 'string'}}
                     register={register}/>

              <RadioBox label="Status"
                        name="status"
                        errors={errors}
                        optionValue={'id'}
                        optionLabel={'name'}
                        options={[{id: "1", name: 'Active'}, {id: '0', name:"Inactive"}]}
                        validationRules={{required:true, type: 'string'}}
                        register={register}/>
            </div>

            <div className="col_6 pr_20">
              <TextArea label="Meta Description"
                        name="meta_description"
                        errors={errors}
                        validationRules={{required: false, type: 'string'}}
                        register={register}/>
            </div>
          </div>

          <div className="col_12">
            <div className="d_flex_end">
              <Button
                text="save"
                isPending={isPending}
                type={"submit"}
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default CategoryForm;