import {configureStore} from "@reduxjs/toolkit";
import authSliceReducer from './slices/authSlice';
import productSliceReducer from "./slices/productSlice";
import orderSlice from "./slices/orderSlice";
import bannerSlice from "./slices/bannerSlice";
export default configureStore({
  reducer: {
    auth: authSliceReducer,
    product: productSliceReducer,
    orders: orderSlice,
    banners: bannerSlice,
  }
})