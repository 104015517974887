import Input from "../../components/UI/form/Input";
import Select from "../../components/UI/form/Select";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import { getCountries} from "../../api/commonRequest";
import {storeVendors, updateVendor} from "../../api/vendor";
import {toast} from "react-toastify";
import {useNavigate, useParams} from 'react-router-dom';
import SubHeadingComponent from "../../components/UI/SubHeadingComponent";
import {setValidationMessage} from "../../util/validationMessage";
import {useState} from "react";
import Button from "../../components/UI/Button";
import RadioBox from "../../components/UI/form/RadioBox";
import Checkbox from "../../components/UI/form/Checkbox";


const VendorFormComponent = ({watch, setError, handleSubmit, errors, register, getValues}) => {
  const navigate = useNavigate();
  const params = useParams();

  const queryClient = useQueryClient();



  const {data: countries} = useQuery({
    queryKey:['countries'],
    queryFn: getCountries
  })


  const [toastId, setToastId] = useState(null);

  const {isPending, mutate} = useMutation({
    mutationFn: params.id ? (data) => updateVendor(params.id, data) : storeVendors,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      toast.update(toastId, { render: `Vendor ${params.id ? 'Updated' : 'Added'}`, type: "success", isLoading: false, autoClose: true });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'vendors',
      });

      navigate('/vendors');
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);
        errorMsg = error.message.message;
      }

      toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
    }
  });

  const onSubmit = (data) => {
    mutate(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SubHeadingComponent heading={`Vendor Information`} />

      <div className="row">
        <div className="col_6 pr_20">
          <Input label="Name"
                 name="name"
                 errors={errors}
                 validationRules={{required: true, type: 'string'}}
                 register={register}/>
        </div>

        <div className="col_6 pr_20">
          <Input label="Email"
                 name="email"
                 type={'email'}
                 errors={errors}
                 validationRules={{required: true, type: 'email'}}
                 register={register}/>
        </div>
        <div className="col_6 pr_20">
          <Input label="Phone"
                 name="phone"
                 errors={errors}
                 validationRules={{required: true, type: 'string'}}
                 register={register}/>
        </div>

        <div className="col_6 pr_20">
          <Input label="Business Name"
                 name="legal_business_name"
                 errors={errors}
                 validationRules={{required: true, type: 'string'}}
                 register={register}/>
        </div>
        <div className="col_6 pr_20">
          <Select
            label="Country"
            name="country_id"
            options={countries}
            errors={errors}
            optionValue={'id'}
            optionLabel={'name'}
            validationRules={{required: true}}
            register={register}/>
        </div>
        <div className="col_6 pr_20">
          <Input label="Address"
                 name="address"
                 errors={errors}
                 validationRules={{required: true, type: 'string'}}
                 register={register}/>
        </div>

        <div className="col_6 pr_20">
          <Input label="City"
                 name="city"
                 errors={errors}
                 validationRules={{required: true, type: 'string'}}
                 register={register}/>
        </div>

        <div className="col_6 pr_20">
          <Input label="Post Code"
                 name="postal_code"
                 errors={errors}
                 validationRules={{required: true, type: 'string'}}
                 register={register}/>
        </div>

        <div className="col_6 pr_20">
          <Input label="Commission"
                 name="commission"
                 errors={errors}
                 type={'number'}
                 validationRules={{required: true, type: 'float'}}
                 register={register}/>
        </div>
        <div className="col_4 pr_20">
          <RadioBox label="Status"
                    name="status"
                    errors={errors}
                    options={[{id: "1", name: 'Active'}, {id: "0", name:"Inactive"}]}
                    validationRules={{required:true, type: 'string'}}
                    register={register}/>
        </div>
        <div className="col_2 ">
          <Checkbox name={`block`}
                    label={"Block Vendor"}
                    classes="mb_0 pt_15"
                    register={register}
                    checked={getValues('block') ? 1 : 0} />
        </div>



      </div>

      {(!params.id) &&
      <>
        <SubHeadingComponent heading={`Admin User Information`} />
        <div className="row">
          <div className="col_6 pr_20">
            <Input label="Name"
                   name="user_name"
                   errors={errors}
                   validationRules={{required: true, type: 'string'}}
                   register={register}/>
          </div>

          <div className="col_6 pr_20">
            <Input label="Email"
                   name="user_email"
                   type={'email'}
                   errors={errors}
                   validationRules={{required: true, type: 'email'}}
                   register={register}/>
          </div>

          <div className="col_6 pr_20">
            <Input label="Password"
                   name="user_password"
                   type={'password'}
                   errors={errors}
                   validationRules={{required: true}}
                   register={register}/>
          </div>
          <div className="col_6 pr_20">
            <Input label="Confirm Password"
                   name="user_confirm_password"
                   type={'password'}
                   errors={errors}
                   validationRules={{required: true}}
                   register={register}/>
          </div>

        </div>
      </>
      }


      <div className="col_12">
        <div className="d_flex_end">
          <Button
            isPending={isPending}
            text="SAVE"
            type="submit"
          />
        </div>
      </div>

    </form>
  );
};

export default VendorFormComponent;