import {createColumnHelper} from "@tanstack/react-table";
import CustomerTableAction from "../../views/customers/CustomerTableAction";
import Card from "../../components/UI/Card";
import Table from "../../components/UI/Table";
import {getCustomers} from "../../api/customers";
import CustomerFilterComponent from "../../views/customers/CustomerFilterComponent";
import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";

const CustomerList = ()=> {
    const location = useLocation();
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor('name', {
            id: 'name',
            header: 'Name'
        }),
        columnHelper.accessor('email', {
            id: 'email',
            header: 'Email'
        }),
        columnHelper.accessor('phone', {
            id: 'phone',
            header: 'Phone'
        }),
        columnHelper.accessor('created_at', {
            id: 'created_at',
            header: 'Created At'
        }),
        columnHelper.accessor('last_login', {
            id: 'last_login',
            header: 'Last Login'
        }),
        columnHelper.display( {
            id: 'actions',
            header: "Actions",
            classes:'text_right',
            cell: props => <CustomerTableAction id={props.row.original.id} />
        }),
    ];

    const [params, setParams] = useState({
        per_page: 20,
        page: 1,
        search: ''
    })

    return (
        <>
            <Card title={"Customers"}>
                <CustomerFilterComponent searchHandler={setParams} params={params} />
                <Table
                    queryKey={"customers"}
                    columns={columns}
                    queryParams={{...params}}
                    queryFn={getCustomers}
                ></Table>
            </Card>
        </>
    )
}

export default CustomerList