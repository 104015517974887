import {createColumnHelper} from "@tanstack/react-table";
import ShippingMethodTableActions from "../../views/settings/shipping-method/ShippingMethodTableActions";
import ShippingMethodCardActions from "../../views/settings/shipping-method/ShippingMethodCardActions";
import Table from "../../components/UI/Table";
import {getShippingMethods} from "../../api/shippingMethods";
import Card from "../../components/UI/Card";
import {getPageSections} from "../../api/pageSections";
import PageSectionTableActions from "../../views/page-sections/PageSectionTableActions";
import SectionCardActions from "../../views/page-sections/SectionCardActions";

const Sections = () => {
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('title', {
      id: 'title',
      header: 'Title'
    }),

    columnHelper.accessor('slug', {
      id: 'slug',
      header: 'Slug'
    }),
    columnHelper.display( {
      id: 'actions',
      header: "Actions",
      classes:'text_right',
      cell: props => <PageSectionTableActions row={props.row.original} />
    }),
  ];
  return (
    <>
      <Card title={"Page Sections"} CardActions={<SectionCardActions />}  >
        <Table
          queryKey={"sections"}
          columns={columns}
          queryParams={{per_page: 10}}
          queryFn={getPageSections}
        />
      </Card>
    </>

  );
}

export default Sections