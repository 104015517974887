import API from "../util/api";

export const getAllPage = async () => {
  let data = null;

  await API.get(`/pages`)
    .then(res => {
      data = res.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const getPage = async (slug) => {
  let data = null;

  await API.get(`/pages/${slug}`)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const addPages = async (payload) => {
  let data = null;

  await API.post('/pages', payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const updatePages = async (slug, payload) => {
  let data = null;
  await API.patch(`/pages/${slug}`, payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const createPage = async ( payload) => {
  let data = null;
  await API.post(`/pages`, payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const deletePage = async (params) => {
  let data = null;

  await API.delete('/pages/'+params.id)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}