import SocialMediaForm from "../../views/social-media/SocialMediaForm";
import { useState} from "react";
import SocialMediaList from "../../views/social-media/SocialMediaList";

const SocialMedia = () => {
  const [modalStatus, setModalStatus] = useState(false)
  const [editItem, setEditItem] = useState(null)

  return (
    <>
      <SocialMediaList setEditItem={setEditItem} setModalStatus={()=>setModalStatus(true)} />
      <SocialMediaForm modalStatus={modalStatus} setModalStatus={setModalStatus} editItem={editItem} setEditItem={setEditItem}  />
    </>
  );
}

export default SocialMedia