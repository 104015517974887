import RadioBox from "../../components/UI/form/RadioBox";
import Input from "../../components/UI/form/Input";
import Button from "../../components/UI/Button";
import {useForm} from "react-hook-form";
import TextEditor from "../../components/UI/form/TextEditor";
import Modal from "../../components/UI/Modal";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../util/validationMessage";
import {useEffect, useState} from "react";
import {createPageSection, updateSections} from "../../api/pageSections";
import {useNavigate} from "react-router-dom";
import TextArea from "../../components/UI/form/TextArea";

const PageSectionForm = ({products, section, modalStatus, setModalStatus, setSection}) => {
  const { reset, register,  setValue, handleSubmit, getValues, setError, formState: { errors }} = useForm({
    defaultValues: {
      status: section ? section.status+'' : "1",
      title: section ? section.title : "",
      link: section ? section.link : "",
      description: section ? section.description : null,
      sort: section ? section.sort : null,
      product_ids: section ? section.product_ids : [],
    },
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [toastId, setToastId] = useState(null);

  const {isPending, mutate} = useMutation({
    mutationFn: section ?  (data) => updateSections(section? section.id : '',data) : (data)=>createPageSection(data),
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      toast.update(toastId, { render: `Section ${section ? 'Updated' : 'Added'}`, type: "success", isLoading: false, autoClose: true });
      if(!section) {
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === 'sections',

        });
        navigate(`/page-sections/${res.id}`);
      }
      setModalStatus(false)
      setSection(res)
      reset();
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);
        errorMsg = error.message.message;
      }

      toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
    }
  });

  useEffect(()=>{
    if(products.length){
        setValue('product_ids', products.map(value => value.id))
    }
  }, [products])


  const onSubmit = (data) => {
    mutate(data)
  }
  return (
    <>
      <Modal title={`Section`} open={modalStatus} setOpen={setModalStatus} size={"modal_600p"}>
        <form onSubmit={handleSubmit(onSubmit)}>

          <div className="row">
            <div className="col_12 pr_20">
              <RadioBox label="Status"
                        name="status"
                        errors={errors}
                        options={[{id: "1", name: 'Active'}, {id: "0", name:"Inactive"}]}
                        validationRules={{required:true, type: 'string'}}
                        register={register}/>
            </div>
            <div className="col_12 pr_20">
              <Input label="Title"
                     name="title"
                     errors={errors}
                     register={register}/>
            </div>

            <div className="col_12 pr_20">
              <TextArea
                label="Description"
                name="description"
                errors={errors}
                placeholder={"Max. 500 letters allowed. The following special characters are not allowed: <, >, {, }, ^, [, ], =, @, ;"}
                register={register}
              />
            </div>

            <div className="col_12 pr_20">
              <Input label="Link"
                     name="link"
                     errors={errors}
                     register={register}/>
            </div>

            <div className="col_12 pr_20">
              <Input label="Sort"
                     name="sort"
                     errors={errors}
                     type={'number'}
                     validationRules={{required:true}}
                     register={register}/>
            </div>

            <div className="col_12">
              <div className="d_flex_end">
                <Button
                  text="save"
                  isPending={isPending}
                  type={"submit"}
                />
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default PageSectionForm