import {useForm} from "react-hook-form";
import {login as loginIn} from "../../api/auth"
import {useMutation} from '@tanstack/react-query';
import {login} from "../../store/slices/authSlice";
import {useDispatch} from "react-redux";

const LoginPage = () => {
  const {register, handleSubmit} = useForm();
  const dispatch = useDispatch();

  const {isPending, error, mutate} = useMutation({
    mutationFn: loginIn,
    onSuccess: (res) => {
      dispatch(login(res));
      window.location.replace("/");
    }
  });

  const submitHandler = (data) => {
    mutate(data);
  };

  return (
    <>
      <div className="login_wrap">
        <div className="login_wrap_inner">
          <div className="login_logo">
            <a href="#"><img src="https://lynkto.net/assets/images/logo-white.png" alt=""/></a>
          </div>
          <form onSubmit={handleSubmit(submitHandler)}>
            <input type="text"
                   id="username"
                   autoComplete="email"
                   className={"my-style"}
                   {...register('email')}
                   placeholder="Enter your email"/>
            <label htmlFor="username">USERNAME</label>

            {(error && error.message.errors['email']) && <div className="has_error username">{error.message.errors['email'][0]}</div>}

            <input type="password"
                   id="password"
                   autoComplete="password"
                   className={"my-style"}
                   {...register('password')}
                   placeholder="Enter your password"/>
            <label htmlFor="password">Password</label>
            {(error && error.message.errors['password']) && <div className="has_error password">{error.message.errors['password'][0]}</div>}




            <button type="submit" disabled={isPending}>Login</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginPage;