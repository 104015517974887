import Button from "../../../components/UI/Button";

const BannerCardButton = ({clickHandler}) => {
  return (
    <Button
      text="+ Add New Banner"
      variant={"btn_success"}
      onClickHandler={clickHandler}
    />
  );
}

export default BannerCardButton