import {getVendorDetails} from "../../api/vendor";
import {useLoaderData} from "react-router-dom";
import Card from "../../components/UI/Card";

export async function loader({params}){
  const [vendor] = await Promise.all([
    getVendorDetails(params.id)
  ]);

  return {vendor};
}

const VendorDetails = () => {
  const {vendor} = useLoaderData();

  return (
    <>
      {vendor &&
      <>
        <Card title={"Store Information"}>
          <div className="table-responsive">

            <table className="table table-border">
              <tbody>
              <tr>
                <td className={'text_right width_200p'}><b>Store Name:</b></td>
                <td>{vendor.name}</td>
                <td className={'text_right width_200p'}><b>Email:</b></td>
                <td>{vendor.email}</td>
              </tr>
              <tr>
                <td className={'text_right width_200p'}><b>Legal Business Name:</b></td>
                <td>{vendor.legal_business_name}</td>
                <td className={'text_right width_200p'}><b>Slug:</b></td>
                <td>{vendor.slug}</td>
              </tr>
              <tr>
                <td className={'text_right'}><b>Logo:</b></td>
                <td><img width={100} src={vendor.logo} alt=""/></td>
                <td className={'text_right'}><b>Commission:</b></td>
                <td>{vendor.commission ? vendor.commission.toFixed(2) : null}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </Card>
        <Card title={"Address"}>

          <div className="table-responsive">

            <table className="table table-border">
              <tbody>
              <tr>
                <td className={'text_right width_200p'}><b>Address:</b></td>
                <td>{vendor.address}</td>
                <td className={'text_right width_200p'}><b>City:</b></td>
                <td>{vendor.city}</td>
              </tr>
              <tr>
                <td className={'text_right width_200p'}><b>Phone:</b></td>
                <td>{vendor.phone}</td>
                <td className={'text_right width_200p'}><b>Post code:</b></td>
                <td>{vendor.postal_code}</td>
              </tr>

              </tbody>
            </table>
          </div>
        </Card>
        {vendor.users.length &&
          <Card title={"users"}>

            <div className="table_responsive">

              <table className="table">
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Last Login</th>
                </tr>
                </thead>
                <tbody>
                {vendor.users.map((user, i)=>(
                <tr key={i}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.last_login}</td>
                </tr>
                ))}

                </tbody>
              </table>
            </div>
          </Card>
        }
      </>
      }
    </>
  )
}

export default VendorDetails