import Button from "../../../components/UI/Button";
import {confirmAlert} from "react-confirm-alert";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {useState} from "react";
import {deleteBanner} from "../../../api/banner";
import {useDispatch} from "react-redux";
import {setEditBanner} from "../../../store/slices/bannerSlice";
import {useParams} from "react-router-dom";

const BannerMediaItemButtons = ({banner}) => {
  const dispatch = useDispatch();
  const {type} = useParams()


  const editHandler = () => {
    dispatch(setEditBanner(banner))
  }

  const queryClient = useQueryClient()
  const [toastId, setToastId] = useState(null);

  const {mutate} = useMutation({
    mutationFn: deleteBanner,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (response, payload) => {
      toast.update(toastId, { render: "Banner Deleted!", type: "success", isLoading: false, autoClose: true  });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === `banners-${type}`,
      });
    },
    onError: () => {
      toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
    }
  })

  const deleteItem = () => {
    confirmAlert({
      title: "Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            mutate({id: banner.id})
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        }
      ]
    })
  }
  return (
    <div className={"mt_5 d_flex_btwn"}>
      <Button
        text="Edit"
        variant={"btn_warning"}
        onClickHandler={editHandler}
      />
      <Button
        text="Delete"
        variant={"btn_danger"}
        onClickHandler={deleteItem}
      />
    </div>
  );
}

export default BannerMediaItemButtons