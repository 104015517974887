import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../../../util/validationMessage";
import {storeSize, updateSize} from "../../../../api/sizes";
import {getVendors} from "../../../../api/vendor";
import Select from "../../../../components/UI/form/Select";
import RadioBox from "../../../../components/UI/form/RadioBox";
import Input from "../../../../components/UI/form/Input";
import Modal from "../../../../components/UI/Modal";
import Checkbox from "../../../../components/UI/form/Checkbox";
import TextArea from "../../../../components/UI/form/TextArea";
import Button from "../../../../components/UI/Button";

const SizeForm = ({size = null, openModal, setOpenModal}) => {
  const {setValue, reset, register,  setError, handleSubmit, formState: { errors }} = useForm({
    defaultValues: {
      "status": "1",
      "default": "1",
    },
  });

  useEffect(() => {
    reset();

    if (size) {
      Object.keys(size).forEach(key => {
        if(key === 'status'){
          setValue(key, ""+size[key]);
        } else {
          setValue(key, size[key]);
        }
      });
    }
  }, [openModal, size]);

  const queryClient = useQueryClient();
  const [toastId, setToastId] = useState(null);

  const {isPending, mutate} = useMutation({
    mutationFn: size ? (data) => updateSize(size.id, data) : storeSize,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      toast.update(toastId, { render: `Size ${size ? 'Updated' : 'Added'}`, type: "success", isLoading: false, autoClose: true });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'sizes',
      });
      setOpenModal(false)
      reset();
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);
        errorMsg = error.message.message;
      }

      toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
    }
  });

  const {data: vendors} = useQuery({
    queryKey:['vendors'],
    queryFn: () => getVendors()
  })

  const onSubmit = (data) => {
    mutate(data)
  }

  return(

    <Modal title={`${size ? 'Edit' : 'Add'} Size`} open={openModal} setOpen={setOpenModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col_6 pr_20">
            <Select
              label="Vendor"
              name="vendor_id"
              options={vendors && vendors.data.length ? vendors.data : []}
              errors={errors}
              optionValue={'id'}
              optionLabel={'name'}
              validationRules={{required: true}}
              register={register}/>
          </div>

          <div className="col_6 pr_20">
            <Input label="Size"
                   name="size"
                   errors={errors}
                   validationRules={{required: true, type: 'string'}}
                   register={register}/>
          </div>

          <div className="col_6 pr_20">
            <Input label="Name"
                   name="name"
                   errors={errors}
                   validationRules={{required: true, type: 'string'}}
                   register={register}/>
          </div>

          <div className="col_3 ">
            <RadioBox label="Status"
                      name="status"
                      errors={errors}
                      optionValue={'id'}
                      optionLabel={'name'}
                      options={[{id: "1", name: 'Active'}, {id: '0', name:"Inactive"}]}
                      validationRules={{required:true, type: 'string'}}
                      register={register}/>
          </div>

          <div className="col_3 ">
            <Checkbox name={`default`}
                      label={"Default"}
                      classes="mb_0 pt_15"
                      checked={true} />
          </div>

          <div className="col_12 pr_20">
            <TextArea label="Description"
                   name="description"
                   errors={errors}
                   validationRules={{required: false, type: 'string'}}
                   register={register}/>
          </div>



        </div>

        <div className="col_12">
          <div className="d_flex_end">
            <Button
              text="save"
              isPending={isPending}
              type={"submit"}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default SizeForm;