import Button from "../../../components/UI/Button";

const ShippingMethodCardActions = ({clickHandler}) => {
  return (
  <Button
    text="+ Add New Shipping Method"
    variant={"btn_primary"}
    onClickHandler={clickHandler}
  />
  );
};

export default ShippingMethodCardActions;