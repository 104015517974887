import Button from "../../components/UI/Button";
import {useEffect, useState} from "react";

export const CustomerFilterComponent = ({searchHandler, params})=>{
  const queryparams = {
    per_page: 20,
    page: 1,
    search: ''
  }

  const [filterParams, setFilterParams] = useState({...queryparams});

  const handleInputChange = (event) => {
    setFilterParams(old=> ({...old, search: event.target.value}))
  }
  const sendSearchRequest = ()=>{
    searchHandler({...filterParams, page:1});
  }

  const resetSearch = ()=>{
    searchHandler({...queryparams})
    setFilterParams({...queryparams})
  }

  const handlePerPage = (e) => {
    searchHandler({...filterParams,  per_page: e.target.value, page:1});
  }

  const enterpress = (e) => {
    if(e.key === 'Enter'){
      sendSearchRequest()
    }
  }

  return (
    <>
      <div className="color_filter_content pt_15">

        <div className="row">
          <div className="col_6">
            <div className="form_row">
              <div className="form_input">
                <div className="search_input">
                  <input type="text" className="form_global"
                         onKeyPress={(e)=>enterpress(e)}
                         placeholder={"search by name, email or phone number"}
                         value={filterParams? filterParams.search : ''}
                         onChange={(event)=>handleInputChange(event)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col_3">
            <Button text={"Search"}
                    variant={"btn_info"}
                    classes={'mr_10 height_40p'}
                    onClickHandler={sendSearchRequest} />

            <Button text={"Reset"}
                    variant={"btn_primary height_40p"}
                    onClickHandler={resetSearch} />
          </div>


          <div className="col_3">
            <div className="d_flex_end">
              <select className="form_global max_width_200p" value={params.per_page} onChange={(event)=>{handlePerPage(event)}}>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="86">86</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomerFilterComponent