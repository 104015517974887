import {useState} from "react";
import Card from "../../../components/UI/Card";
import CourierFormComponent from "../../../views/settings/courier/CourierFormComponent";
import {getCouriers} from "../../../api/couriers";
import CourierCardActions from "../../../views/settings/courier/CourierCardActions";
import {createColumnHelper} from "@tanstack/react-table";
import Table from "../../../components/UI/Table";
import CourierTableActions from "../../../views/settings/courier/CourierTableActions";

const CourierPageComponent = () => {
  const [openModal, setOpenModal] = useState(false)
  const [courier, setCourier] = useState(null)

  const editHandler = (row) =>{
    setCourier(row)
    setOpenModal(true)
  }

  const addNewCourier = () =>{
    setCourier(null);
    setOpenModal(true);
  }

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: 'Name'
    }),

    columnHelper.accessor('created_at', {
      id: 'created_at',
      header: 'Created At'
    }),
    columnHelper.display( {
      id: 'actions',
      header: "Actions",
      classes:'text_right',
      cell: props => <CourierTableActions editRecord={editHandler} row={props.row.original} />
    }),
  ];

  return (
    <>
      <Card title={"Couriers"}  CardActions={<CourierCardActions clickHandler={addNewCourier}/>}>
        <Table
          queryKey={"couriers"}
          columns={columns}
          queryParams={{per_page: 10}}
          queryFn={getCouriers}
        />
      </Card>

      <CourierFormComponent editRecord={courier}
                 openModal={openModal}
                 setOpenModal={setOpenModal} />
    </>
  )
}

export default CourierPageComponent;