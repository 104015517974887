import Card from "../../components/UI/Card";
import VendorFormComponent from "../../views/vendors/VendorFormComponent";
import {useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import {useMutation} from "@tanstack/react-query";
import {getVendorDetails} from "../../api/vendor";
import {useForm} from "react-hook-form";
import Loading from "../../components/UI/Loading";

const VendorAddEdit = () => {
  const params = useParams();
  const location = useLocation();

  const {setValue, reset, register, setError, getValues,   watch, handleSubmit, formState: { errors }} = useForm({
    defaultValues: {
      "setting.location": 'US',
      "status": '1',
      "block": 0,
    },
  });

  useEffect(() => {
    reset();
  }, [location]);

  const {mutate, isPending} = useMutation({
    mutationFn: () => getVendorDetails(params.id),
    onSuccess: (data) => {
      Object.keys(data).forEach(key => {
        setValue(key, data[key]);
      });
    }
  })

  useEffect(() => {
    if (params.id) {
      mutate();
    }
  }, []);



  return (
    <>
      <div className="row">
        <div className="col_12">
          <Card title={`${params.id ? 'Edit Vendor' : 'Add New Vendor'}`}>
            {isPending ?
              <Loading /> :
              <VendorFormComponent watch={watch}
                                   setError={setError}
                                   handleSubmit={handleSubmit}
                                   errors={errors}
                                   getValues={getValues}
                                   register={register} />
            }
          </Card>
        </div>
      </div>
    </>
  );
}

export default VendorAddEdit;