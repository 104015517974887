import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../../util/validationMessage";
import Modal from "../../../components/UI/Modal";
import Input from "../../../components/UI/form/Input";
import Button from "../../../components/UI/Button";
import {storeShippingMethod, updateShippingMethod} from "../../../api/shippingMethods";
import {getCouriers} from "../../../api/couriers";
import Select from "../../../components/UI/form/Select";
import RadioBox from "../../../components/UI/form/RadioBox";

const ShippingMethodFormComponent = ({editRecord = null, openModal, setOpenModal}) => {
  const {setValue, reset, register, setError, handleSubmit, formState: { errors }} = useForm({
    defaultValues:{
      status: '1'
    }
  });

  useEffect(() => {
    reset();
    if (editRecord) {
      Object.keys(editRecord).forEach(key => {
        setValue(key, editRecord[key]);
      });
    }
  }, [openModal, editRecord]);


  const queryClient = useQueryClient();
  const [toastId, setToastId] = useState(null);

  const {isPending, mutate} = useMutation({
    mutationFn: editRecord ? (data) => updateShippingMethod(editRecord.id, data) : storeShippingMethod,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      toast.update(toastId, { render: `Shipping Method ${editRecord ? 'Updated' : 'Added'}`, type: "success", isLoading: false, autoClose: true });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'shipping-methods',
      });
      setOpenModal(false)
      reset();
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);
        errorMsg = error.message.message;
      }

      toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
    }
  });

  const onSubmit = (data) => {
    mutate(data)
  }

  const {data: couriers} = useQuery({
    queryKey:['couriers'],
    queryFn: () => getCouriers()
  })

  return(
    <>
      <Modal title={`${editRecord ? 'Edit' : 'Add'} Shipping Method`} open={openModal} setOpen={setOpenModal}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col_12">
              <Input label="Name"
                     name="name"
                     errors={errors}
                     validationRules={{required: true, type: 'string'}}
                     register={register}/>
            </div>
            <div className="col_12 pr_20">
              <Select
                label="Courier"
                name="courier_id"
                options={couriers && couriers.data.length ? couriers.data : []}
                errors={errors}
                optionValue={'id'}
                optionLabel={'name'}
                validationRules={{required: true}}
                register={register}/>
            </div>
            <div className="col_12">
              <Input label="Fees"
                     name="fee"
                     errors={errors}
                     validationRules={{required: true, type: 'double'}}
                     register={register}/>
            </div>
            <div className="col_12 pr_20">
              <RadioBox label="Status"
                        name="status"
                        errors={errors}
                        options={[{id: "1", name: 'Active'}, {id: "0", name:"Inactive"}]}
                        validationRules={{required:true, type: 'string'}}
                        register={register}/>
            </div>
          </div>

          <div className="col_12 mb_10">
            <div className="d_flex_end">
              <Button
                text="save"
                isPending={isPending}
                type={"submit"}
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default ShippingMethodFormComponent;