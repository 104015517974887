import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

import {useMutation} from "@tanstack/react-query";
import {toast} from "react-toastify";
import Checkbox from "../../../../components/UI/form/Checkbox";
import {changeSizeStatus} from "../../../../api/sizes";

const SizeTableActiveComponent = ({id,active})=>{
  const [isActive, setIsActive] = useState(active);
  const [toastId, setToastId] = useState(null);

  const {mutate, isPending} = useMutation({
    mutationFn: changeSizeStatus,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (response, payload) => {
      setIsActive(payload.status)

      toast.update(toastId, { render: "Active status updated", type: "success", isLoading: false, autoClose: true  });
    },
    onError: () => {
      toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
    }
  })

  const activeChangeHandler = (e) => {
    mutate({id, status: +e.target.checked});
  };

  return (
    <>
      {isPending && <FontAwesomeIcon icon={faSpinner} spin />}
      {!isPending && <Checkbox name={`active `+ id}
                               classes="mb_0"
                               checked={isActive}
                               onChangeHandler={(event) => activeChangeHandler(event)} />}
    </>
  );
}

export default SizeTableActiveComponent;