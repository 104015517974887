import {Link} from "react-router-dom";
import {confirmAlert,} from "react-confirm-alert";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {customerDelete} from "../../api/customers";
import {toast} from "react-toastify";
import {useState} from "react";
import ReactiveButton from "reactive-button";
import Button from "../../components/UI/Button";

const CustomerTableAction = ({id}) =>{

    const [toastId, setToastId] = useState(null);

    const queryClient = useQueryClient()

    const {mutate, isPending} = useMutation({
        mutationFn: customerDelete,
        onMutate: () => {
            setToastId(toast.loading("Please wait..."))
        },
        onSuccess: (response, payload) => {
            toast.update(toastId, { render: "Customer Deleted!", type: "success", isLoading: false, autoClose: true  });
            queryClient.invalidateQueries({
                predicate: (query) => query.queryKey[0] === 'customers',
            });
        },
        onError: () => {
            toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
        }
    })

    const deleteHandler = () => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        mutate({id: id})
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    },
                }
            ]
        });
    }
    return (
        <>
            <Link  to={"/customers/edit/"+id} className={"mr_5"}>
                <Button text={'Edit'} variant={'btn_warning'} />
            </Link>
            <Button text={'Delete'} variant={'btn_danger'} isPending={isPending} onClickHandler={deleteHandler} />
        </>
    );
}

export default CustomerTableAction;