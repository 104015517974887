import {useForm, useWatch} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import RadioBox from "../../components/UI/form/RadioBox";
import Input from "../../components/UI/form/Input";
import TextEditor from "../../components/UI/form/TextEditor";
import Button from "../../components/UI/Button";
import {useEffect, useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../util/validationMessage";
import {createPage, updatePages} from "../../api/page";

const PageFormComponent = ({page}) => {
  const params = useParams();
  const [toastId, setToastId] = useState(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate()
  const {  register,  setValue, getValues, watch, setError, reset, handleSubmit, formState: { errors }} = useForm({
    defaultValues: {
      slug: page ? page.slug : '',
      content_en: page ? page.content_en : '',
      content_ko: page ? page.content_ko : '',
      meta_title: page ? page.meta_title : '',
      title_en: page ? page.title_en : '',
      title_ko: page ? page.title_ko : '',
      meta_description: page ? page.meta_description : '',
      status: '1',
    },
  });

  useEffect(()=>{
    reset()
  }, [params])

  useEffect(()=>{
    if(page){
      setValue('content_en', page.content_en)
      setValue('content_ko', page.content_ko)
      setValue('status', page.status+'')
      setValue('title_en', page.title_en)
      setValue('title_ko', page.title_ko)
      setValue('slug', page.slug)
      setValue('meta_title', page.meta_title)
      setValue('meta_description', page.meta_description)
    }
  }, [page])

  const {isPending, mutate} = useMutation({
    mutationFn: page ?  (data)=> updatePages(page.id, data) :(data)=> createPage(data),
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      toast.update(toastId, { render: `Page ${page? 'Update' : 'create' }`, type: "success", isLoading: false, autoClose: true });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'pages',
      });
      navigate('/pages');
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);
        errorMsg = error.message.message;
      }

      toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
    }
  });

  function changeString(str){
    return str.replace(/[^A-Z0-9]/ig, "-");
  }

  useEffect(()=>{
    if(getValues('title_en')){
      setValue('slug', changeString(getValues('title_en')))
    }
  }, [watch('title_en')])


  const onSubmit = (data) => {
    mutate(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col_12 pr_20">
          <RadioBox label="Status"
                    name="status"
                    errors={errors}
                    options={[{id: "1", name: 'Active'}, {id: "0", name:"Inactive"}]}
                    validationRules={{required:true, type: 'string'}}
                    register={register}/>
        </div>
        <div className="col_6 pr_20">
          <Input label="Title (en)"
                 name="title_en"
                 errors={errors}
                 validationRules={{required:true, type: 'string'}}
                 register={register}/>
        </div>
        <div className="col_6 pr_20">
          <Input label="Title (ko)"
                 name="title_ko"
                 errors={errors}
                 validationRules={{required:true, type: 'string'}}
                 register={register}/>
        </div>
        <div className="col_6 pr_20">
          <Input label="Slug"
                 name="slug"
                 errors={errors}
                 validationRules={{required:true, type: 'string'}}
                 register={register}/>
        </div>
        <div className="col_6 pr_20">
          <Input label="Meta Title"
                 name="meta_title"
                 errors={errors}
                 register={register}/>
        </div>
        <div className="col_6 pr_20">
          <Input label="Meta Description"
                 name="meta_description"
                 errors={errors}
                 register={register}/>
        </div>
        <div className="col_12 pr_20">
          <TextEditor
            label="Page Content (en)"
            name="content_en"
            placeholder={"Max. 500 letters allowed. The following special characters are not allowed: <, >, {, }, ^, [, ], =, @, ;"}
            errors={errors}
            validationRules={{type: 'string'}}
            data={ page && page.content_en != null? page.content_en : ''}
            onchangeHandler={(data) => setValue('content_en', data)}
            register={register}
          />
        </div>
        <div className="col_12 pr_20">
          <TextEditor
            label="Page Content (ko)"
            name="content_ko"
            placeholder={"Max. 500 letters allowed. The following special characters are not allowed: <, >, {, }, ^, [, ], =, @, ;"}
            errors={errors}
            validationRules={{type: 'string'}}
            data={ page && page.content_ko != null? page.content_ko : ''}
            onchangeHandler={(data) => setValue('content_ko', data)}
            register={register}
          />
        </div>
        <div className="col_12">
          <div className="d_flex_end">
            <Button
              text="save"
              isPending={isPending}
              type={"submit"}
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default PageFormComponent