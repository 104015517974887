import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";

const Pagination = ({meta, changePageFn, isPlaceholderData}) => {
  let current_page = meta.current_page
  let last_page = meta.last_page

  const [visiblePages, setVisiblePages] = useState([]);
  const location = useLocation();

  const generatePages = () => {
    const visibleCount = 5;
    const half = Math.floor(visibleCount / 2);

    let pages = [];

    pages.push(1);

    if (current_page > half + 2) {
      pages.push("...");
    }

    for (let i = Math.max(current_page - half, 2); i <= Math.min(current_page + half, last_page - 1); i++) {
      pages.push(i);
    }

    if (current_page < last_page - half - 1) {
      pages.push("...");
    }

    pages.push(last_page);

    setVisiblePages(pages);
  };

  useEffect(()=>{
    generatePages()
  }, [meta])

  const paginationBtnHandler = (page) => {
    if (page === meta.current_page)
      return;

    changePageFn(page);
  };

  return (
    <>
      {meta.last_page > 1 ?
      <div className="retail_pagination">
        <p>{meta.total > 0 ? meta.from +'-'+ meta.to : 0} of {meta.total}</p>
        <ul>
          {current_page !== 1 && <li key={'first'}>
            <a className={"cursor_pointer"} onClick={() => changePageFn(meta.current_page - 1)} disabled={meta.current_page === 1}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                   stroke="currentColor" className="w-4 h-4 mr-[1px] lg:w-5 lg:h-5 lg:mr-[2px]">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5"></path>
              </svg>
            </a>
          </li>}

          {visiblePages.map((page, i) => (
            <li key={`paginate_${i}`} className={`cursor_pointer ${page === meta.current_page ? 'opacity_5' : ''}`}>
              {(page) === current_page ? (
                <span>{page}</span>
              ) : (
                <>
                  { page === "..." ? '...' :  <a className={"cursor_pointer"} onClick={(event) => paginationBtnHandler( page)}>{page}</a> }
                </>
              )}
            </li>))}

          {current_page !== last_page && <li key={'last'} disabled={isPlaceholderData || (meta?.last_page === meta.current_page)}>
            <a className={"cursor_pointer"} onClick={(event) =>  paginationBtnHandler( current_page + 1)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                   stroke="currentColor" className="w-4 h-4 ml-[1px] lg:w-5 lg:h-5 lg:ml-[2px]">
                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"></path>
              </svg>
            </a>
          </li>}
        </ul>
      </div>
        : '' }
    </>
  );
};

export default Pagination;