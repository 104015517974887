import Card from "../../../components/UI/Card";
import {useEffect, useState} from "react";

import {useQuery, useQueryClient} from "@tanstack/react-query";
import {getBanners, sortBanner} from "../../../api/banner";
import PreloaderComponent from "../../../components/UI/PreloaderComponent";
import BannerMediaItem from "../../../views/banners/common-banners/BannerMediaItem";
import {ReactSortable} from "react-sortablejs";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";
import BannerForm from "../../../views/banners/common-banners/BannerForm";
import BannerCardButton from "../../../views/banners/common-banners/BannerCardButton";

const CommonBanner = () => {
  const {type} = useParams()
  const [modalStatus, setModalStatus] = useState(false)
  const [banners, setBanners] = useState([])
  const [singleBanner, setSingleBanner] = useState(false)
  const [showTextField, setTextField] = useState(false)

  const addNewBanner = () => {
    setModalStatus(true)
  }

  const {data: allBanners, isPending} = useQuery({
    queryKey:[`banners-${type}`],
    queryFn: () => getBanners({type: type})//Home page Top Banner Type 2 fixed
  })

  useEffect(()=>{
    if(allBanners){
      setBanners(allBanners)
    }
  }, [allBanners])

  useEffect(()=>{
    if(!isPending) {
      if (banners.length === 0) {
        setSingleBanner(true)
      } else {
        if(+type === 1 || +type === 3 || +type === 5){
          setSingleBanner(true)
        } else {
          setSingleBanner(false)
        }
      }
      if (+type === 5) {
        setTextField(true)
      } else{
        setTextField(false)
      }
    }
  }, [banners])

  const bannerShort = (list) => {
    const checkEquality = JSON.stringify(list) === JSON.stringify(banners);
    if(!checkEquality){
      setBanners(list)
      sortBanner( list).then(()=>{
        toast.success('Banner Sort Updated!' );
      })
    }
  }

  return (
    <>
      <Card title={"Banners"} CardActions={singleBanner && <BannerCardButton clickHandler={addNewBanner} />}>
        <div className="row">
          {isPending && <PreloaderComponent />}
          {banners &&
          <ReactSortable animation={200}
                         className={"width_full d_inline_flex"}
                         list={banners}
                         setList={bannerShort}>
            {banners && banners.map(item => (
              <BannerMediaItem banner={item} key={item.id}/>
            ))}
          </ReactSortable>
          }
        </div>
        {(!banners.length && !isPending ) && <span className={"d_flex_center"}>No Result Found yet!</span>}
      </Card>
      <BannerForm modalStatus={modalStatus} setModalStatus={setModalStatus} showTextField={showTextField} type={type} />
    </>
  );
}

export default CommonBanner