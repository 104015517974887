import {Link} from "react-router-dom";
import Button from "../../../components/UI/Button";
import Card from "../../../components/UI/Card";
import React, {useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {deleteCouponCode} from "../../../api/coupon";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import AddCodeForm from "./AddCodeForm";
import AddCodeCardButton from "./AddCodeCardButton";

const CouponPromoCodesCard = ({coupon, deleteConfirm})=>{
    const [toastId, setToastId] = useState(null);
    const [openModal, setOpenModal] = useState(false)
    const {mutate,isPending} = useMutation({
        mutationFn: deleteCouponCode,
        onMutate: () => {
            setToastId(toast.loading("Please wait..."))
        },
        onSuccess: (response, payload) => {
            toast.update(toastId, { render: "Code Deleted!", type: "success", isLoading: false, autoClose: true  });
            deleteConfirm()
        },
        onError: () => {
            toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
        }
    })

    const deleteHandler = (id) => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        mutate({id: id})
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });

    };
    return (
        <>
        <Card title={"Promotion Codes"} CardActions={<AddCodeCardButton setModalStatus={setOpenModal} />}>
            <div className="row">
                <div className="col_12">
                    <div className="table_responsive">
                        {coupon.codes.length ?
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Code</th>
                                <th> Redemptions</th>
                                <th>Expires</th>
                                <th>Created</th>
                                <th style={{'textAlign':"right"}}>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {coupon.codes.map((item, i)=>(
                                <tr key={'code'+i}>
                                    <td>{`${item.code}`}</td>
                                    <td>{item.times_redeemed ? item.times_redeemed : 0 }{item.max_redemptions ? `/${item.max_redemptions}` : ''}</td>
                                    <td>{item.expires_at}</td>
                                    <td>{item.created_at}</td>
                                    <td style={{'textAlign':"right"}}>
                                        <Link  to={"/coupons-code/"+item.id} className="mr_5">
                                            <Button text={'Details'} variant={'btn_info'} />
                                        </Link>
                                        <Button text={'Delete'} variant={'btn_danger'}  isPending={isPending} onClickHandler={()=>deleteHandler(item.id)}  />
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table> : ''
                        }
                    </div>
                </div>
            </div>
        </Card>
            <AddCodeForm
                coupon={coupon}
                openModal={openModal}
                setOpenModal={setOpenModal}
                updateConfirm={deleteConfirm}
            />
        </>
    )
}

export default CouponPromoCodesCard