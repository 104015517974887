import Button from "../../components/UI/Button";
import {Link} from "react-router-dom";

const  CouponListCardAction = ()=>{
    return (
        <Link  to={"/coupons/create"} className="mr_5">
            <Button text={'Add New Coupon'} variant={'btn_info'} />
        </Link>
    );
}
export default CouponListCardAction