import DropzoneComponent from "../../../../../components/UI/DropzoneComponent";
import {useEffect, useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {deleteDraftImage} from "../../../../../api/temFileDelete";
import {toast} from "react-toastify";
import {useLoaderData} from "react-router-dom";
import {ReactSortable} from "react-sortablejs";
import UploadedMediaItemComponent from "./UploadedMediaItemComponent";
import ProductImageUploadDropzone from "./ProductImageUploadDropzone";
import {useSelector} from "react-redux";
import {getValue} from "@testing-library/user-event/dist/utils";

const MediaComponent = ({watch, setValue, errors, register, }) => {
  const product = useSelector(state => state.product.currentEditProduct)
  const [images, setImages] = useState([]);
  const [toastId, setToastId] = useState(null);
  const [currentDeleteItem, setCurrentDeleteItem] = useState(null);



  useEffect(() => {
    if (product) {
      setImages(product.media.map(image => ({
        id: image.id,
        url: image.url,
        location: image.location,
        sort: image.sort
      })));
    }
  }, []);

  const imageUploadSuccessEvent = (response) =>{
    setImages(oldImages => [...oldImages, {...response.data, sort: (images.length + 1)}]);
  }

  const {mutate:deleteMutation, isPending} = useMutation({
    mutationFn: deleteDraftImage,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (response, payload) => {
      setImages(images => [...images.filter((item) => { return item.url != payload.url})]);
      toast.update(toastId, { render: "Image deleted!", type: "success", isLoading: false, autoClose: true  });
    },
  })

  useEffect(() => {
    setValue('media', images.map((image, i) => ({...image, sort: (i + 1)})));
  }, [images]);



  const deleteProductImage = (image, index) => {
    if(image) {
      setCurrentDeleteItem(index)
      deleteMutation(image)
    }
  }

  return (
    <>
      {(images && images.length > 0) &&
      <div className="imageWrapper mb_15 ">
        <div className="images_outer width_full d_inline_flex">
          <ReactSortable animation={200}
                         list={images}
                         setList={setImages}
                         className="row width_full">
            {images.map((image,index) => (
                <UploadedMediaItemComponent
                  isPending={isPending}
                  currentDeleteItem={currentDeleteItem}
                  image={image}
                  deleteProduct={deleteProductImage}
                  indexKey={index}
                  key={index} />
              )
            )}
          </ReactSortable>
        </div>
      </div>
      }

      {/*<ProductImageUploadDropzone id={`dropzon`} options={{url: process.env.REACT_APP_API_BASE_URL+'/products/image', maxFiles: 20}} removeItemAfterSuccess={true} successEventHandler={imageUploadSuccessEvent} />*/}
    </>
  );
}

export default MediaComponent;