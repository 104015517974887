import ProductComponent from "../products/product/List/includes/ProductComponent";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {sortPageSectionProducts} from "../../api/pageSections";
import {ReactSortable} from "react-sortablejs";
import {useParams} from "react-router-dom";

const SectionProductsComponent = ({products : allproducts}) => {
  const params = useParams()

  const [products, setProducts] = useState([])

  useEffect(()=>{
    if(allproducts.length){
      setProducts(allproducts)
    }
  }, [allproducts])


  const bannerShort = (list) => {
    const checkEquality = JSON.stringify(list) === JSON.stringify(products);
    if(!checkEquality){
      setProducts(list)
      const arrayOfIds = list.map(obj => obj.id);
      sortPageSectionProducts( {ids:arrayOfIds, section:params.id}).then(()=>{
        toast.success('Product Sort Updated!' );
      })
    }
  }

  return (
    <div className="item_list_wrapper">
      <div className="item_list_wrap">
        <ReactSortable animation={200}
                       className={"width_full d_inline_flex"}
                       list={products}
                       setList={bannerShort}>
        {products.map((product, index) => (
          <ProductComponent product={product} key={index}/>
        ))}
        </ReactSortable>.
      </div>
    </div>
  );
}

export default SectionProductsComponent