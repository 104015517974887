import {useSelector} from "react-redux";
import Card from "../../../../components/UI/Card";

const VendorInformation = () => {
  const {orderForm} = useSelector(state => state.orders)
  return(
    <Card>
      <h1 className="common_sub_title d_flex_btwn mt_0 mb_5">
        <span> Vendor Information</span>
      </h1>
      {(orderForm && orderForm.vendor) &&
      <p className="mb_5">
        <b>Name:</b> {orderForm.vendor.name} <br/>
        <b>Email:</b> {orderForm.vendor.email} <br/>
        <b>Phone:</b> {orderForm.vendor.phone} <br/>
      </p>
      }
    </Card>
  )
}

export default VendorInformation