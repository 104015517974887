import API from "../util/api";

export const getColors = async (params) => {
  let data = null;

  await API.get('/colors', {params})
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const deleteColor = async (params) => {
  let data = null;

  await API.delete('/colors/'+params.id)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const storeColor = async (payload) => {
  let data = null;

  await API.post('/colors', payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const updateColor = async (id, payload) => {
  let data = null;

  await API.patch(`/colors/${id}`, payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}