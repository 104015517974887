import Card from "../../../../components/UI/Card";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

const OrderSummaryComponent = ({setValue, control, errors, getValues, register, watch}) => {

  const {products} = useSelector(state => state.orders.orderForm);
  const {orderForm} = useSelector(state => state.orders);
  const [orderSummary, setOrderSummary] = useState({total:0, subTotal: 0, discount: 0, shippingCost: 0})

  const params = useParams()

  useEffect(()=>{
    if(params && params.id && orderForm){
      setOrderSummary({
        total:orderForm.total,
        subTotal: orderForm.sub_total,
        discount: orderForm.discount,
        shippingCost: orderForm.shipping_cost
      })
    }
  }, [params, orderForm])


  useEffect(()=>{
    setOrderSummary(rows => ({...rows, shippingCost: +getValues('shipping_cost')}))
  }, [watch('shipping_method_id')])

  useEffect(()=>{
    calculator()
  }, [products, orderSummary.discount, orderSummary.shippingCost])

  const calculator = () => {
    let subTotal = 0
    products.map(item => {
      subTotal += (item.quantity * item.price)
    })
    let total = subTotal
    total = total + orderSummary.shippingCost
    if(orderSummary.discount <= subTotal){
      total = total - orderSummary.discount
    }
    setValue('discount', orderSummary.discount)
    setOrderSummary(rows => ({...rows, subTotal: subTotal, total: total}))

  }

  const discountChange = (e) => {
    setOrderSummary(rows => ({...rows, discount: +e.target.value}))
  }

  return(
    <Card title={"Order Summary"}>
      <div className="d_flex_end">
        <div className="col_6 text_right"></div>
        <div className="col_6 text_right">
          <table className="table border_none">
            <tbody>
            <tr>
              <td className={"text_left"}><b>Sub Total:</b></td>
              <td className={"pr_0"}>${orderSummary.subTotal ? +orderSummary.subTotal.toFixed(2) : '00.00'}</td>
            </tr> 
            <tr>
              <td className={"text_left"}><b>Discount:</b></td>
              <td className={"pr_0"}>
                {orderSummary.discount}
                {/*<input type="number" className="form_global pr_5 text_right" value={orderSummary.discount} onChange={discountChange} />*/}
              </td>
            </tr>
            <tr>
              <td className={"text_left"}><b>Shipping Cost:</b></td>
              <td className={"pr_0"}>
                {orderSummary.shippingCost ? orderSummary.shippingCost.toFixed(2) : '00.00'}
              </td>
            </tr>
            <tr>
              <td className={"text_left"}><b>Total:</b></td>
              <td className={"pr_0"}>${orderSummary.total ? +orderSummary.total.toFixed(2) : '00.00'}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Card>
  );
}

export default OrderSummaryComponent