import API from "../util/api";

export const login = async (payload) => {
  let data = null;

  await API.post('/login', payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const getUser = async (payload) => {
  let data = null;

  await API.get('/user', payload)
    .then(res => {
      data = res.data;
      return data;
    })
    .catch((err) => {
      if(err.response.status === 401){
        return JSON.stringify({message:'unauthorized'})
      }
    });

  return data;
}


export const updatePassword = async (payload) => {
  let data = null;

  await API.post('/update-password', payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}