import {createColumnHelper} from "@tanstack/react-table";
import {useState} from "react";
import Card from "../../../../components/UI/Card";
import Table from "../../../../components/UI/Table";
import {getMadeIn} from "../../../../api/madeIns";
import MadeInListActions from "../../../../views/products/settings/made-ins/MadeInListActions";
import MadeInForm from "../../../../views/products/settings/made-ins/MadeInForm";
import MadeInTableActions from "../../../../views/products/settings/made-ins/MadeInTableActions";
import MadeInActiveComponent from "../../../../views/products/settings/made-ins/MadeInActiveComponent";
import MadeInTableDefaultStatusComponent
  from "../../../../views/products/settings/made-ins/MadeInTableDefaultStatusComponent";

const MasterColorList = () => {
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('vendor.name', {
      id: 'vendor.name',
      header: 'Vendor'
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: 'Name'
    }),

    columnHelper.display( {
      id: 'active',
      header: "Active",
      cell: props => <MadeInActiveComponent id={props.row.original.id}
                                               active={props.row.original.status} />
    }),

    columnHelper.display( {
      id: 'default',
      header: "Default",
      cell: props => <MadeInTableDefaultStatusComponent id={props.row.original.id}
                                                active={props.row.original.default} />
    }),

    columnHelper.display( {
      id: 'actions',
      header: "Actions",
      classes:'text_right',
      cell: props => <MadeInTableActions editMadeIn={editHandler} row={props.row.original} />
    }),
  ];


  const [madeIn, setMadeIn] = useState('')
  const [openModal, setOpenModal] = useState(false)


  const editHandler = (madeIn) =>{
    setMadeIn(madeIn)
    setOpenModal(true)
  }

  const addNewMadeIn = () =>{
    setMadeIn(null)
    setOpenModal(true)
  }

  return (
    <>
      <Card title={"Made IN"}  CardActions={<MadeInListActions clickHandler={addNewMadeIn}/>}>
        <Table
          queryKey={"made-in"}
          columns={columns}
          queryParams={{per_page: 10}}
          queryFn={getMadeIn}
        />
      </Card>
      <MadeInForm madeIn={madeIn} openModal={openModal} setOpenModal={setOpenModal} />
    </>
  )
}

export default MasterColorList;