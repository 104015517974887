import {useEffect, useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {getShippingMethods} from "../../../../api/shippingMethods";
import Modal from "../../../../components/UI/Modal";
import Button from "../../../../components/UI/Button";
import PreloaderComponent from "../../../../components/UI/PreloaderComponent";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const ShippingMethodsList = ({setShippingMethod, setModal, openModal}) => {
  const [shippingMethods, setShippingMethods] = useState([])
  const params = useParams()
  const {orderForm} = useSelector(state => state.orders);
  const {mutate, isPending} = useMutation({
    mutationFn: () => getShippingMethods(),
    onSuccess: (data) => {
      if(data.data.length ) {
        if(params && params.id){
          setShippingMethod(data.data.find(s=>s.id === orderForm.shipping_method_id))
        } else {
          setShippingMethod(data.data[0])
        }

      }
      setShippingMethods(data.data)
    }
  })

  useEffect(()=>{
    if(!shippingMethods.length){
      mutate()
    }
  }, [])

  const selectedAddress = (method) => {
    setShippingMethod(method)
    setModal(false)
  }
  return (
    <Modal title={`Shipping Methods`} open={openModal} setOpen={setModal}>
      <div className="table_responsive">
        {(shippingMethods && shippingMethods.length) &&
        <table className={"table"}>
          <thead>
          <tr>
            <th><b>#</b></th>
            <th><b>Name</b></th>
            <th><b>Courier</b></th>
            <th><b>Cost</b></th>
            <th className={"text_right width_100p"}><b>#</b></th>
          </tr>
          </thead>
          <tbody>
          { shippingMethods.map((address, i)=>(
            <tr key={address.id}>
              <td>{i+1}</td>
              <td>{address.name}</td>
              <td>{address.courier ? address.courier.name : ''}</td>
              <td>${address.fee ? address.fee.toFixed(2) : 'Actual Rate'} </td>
              <td className={"text_right width_100p"}><Button onClickHandler={()=>selectedAddress(address)}  text={"Select"} /></td>
            </tr>))  }
          </tbody>
        </table>
        }
      </div>
    </Modal>
  );
}

export default ShippingMethodsList