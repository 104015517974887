import Card from "../../../../components/UI/Card";
import ColorListActions from "../../../../views/products/settings/colors/ColorListActions";
import { useQuery} from "@tanstack/react-query";
import {getColors} from "../../../../api/colors";
import ColorItem from "../../../../views/products/settings/colors/ColorItem";
import ColorForm from "../../../../views/products/settings/colors/ColorForm";
import {useState} from "react";

const ColorList = () => {
  const [openModal, setOpenModal] = useState(false)
  const [color, setColor] = useState(null)

  const { data: colors } = useQuery({
    queryKey: ['colors'],
    queryFn:()=> getColors({page:1,per_page:10})
  });

  const editHandler = (id) =>{
    const color = colors.find(item => item.id === id)
    setColor(color);
    setOpenModal(true);
  }

  const addNewColor = () =>{
    setColor(null);
    setOpenModal(true);
  }

  return (
    <>
      <Card title={"Colors"}  CardActions={<ColorListActions clickHandler={addNewColor}/>}>
        <div className="item_color_main_wrap">
          <div className="item_color_list_wrap">
            <div className="item_color_wrap">
              {(colors && colors.length)  &&
                colors.map((color)=>(
                  <ColorItem color={color} key={color.id} handleClick={editHandler} />
                ))
              }
            </div>
          </div>
        </div>
      </Card>

      <ColorForm color={color}
                 openModal={openModal}
                 setOpenModal={setOpenModal} />
    </>
  )
}

export default ColorList;