import { NavLink, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {activeRoute} from "../../util/helper";
import {getPageSections} from "../../api/pageSections";
import {useQuery} from "@tanstack/react-query";
import {getBanners} from "../../api/banner";
import {getAllPage, getPage} from "../../api/page";

const LeftNavComponent = () => {
  const [parentIndex, setParentIndex] = useState(null);

  const selectParentMenu = (index) => {
    setParentIndex((prevIndex) => (prevIndex === index ? null : index));
  };


  const location = useLocation()
  useEffect(() => {
    let currentRoute = activeRoute(location.pathname)
    if(currentRoute.length)
      setParentIndex(currentRoute[0])

    if(!currentRoute.length)
      setParentIndex(null)
  }, [location]);

  const redirectHandler = (event, to) => {
    if (to === location.pathname) {
      event.preventDefault();
    }
  };

  const {data: sections, isPending} = useQuery({
    queryKey:[`sections`],
    queryFn: () => getPageSections()
  })
  const {data: pages} = useQuery({
    queryKey:[`pages`],
    queryFn: () => getAllPage()
  })

  return (
    <div id="left_nav">
      <ul className="left_nav_list">
        <li>
          <div className={`left_f_list no_arrow cursor_pointer ${!parentIndex ? 'active' : ''}`  }>

            <NavLink onClick={(event) => redirectHandler(event, '/')}
                     end to="/"> <span className={'left_img'}><i className="font_16p fas fa-tachometer-alt"></i></span><span>Dashboard</span></NavLink>
          </div>
        </li>
        <li>
          <div className={`left_f_list cursor_pointer ${parentIndex === 'vendors' ? ' open active' : ''}`  } onClick={() => selectParentMenu('vendors')}>
            <span className={'left_img'}><i className="font_16p fas fa-users"></i></span> <span>Vendors</span>
          </div>

          <ul className={`sub_accordion first_level ${parentIndex === 'vendors' ? ' current' : ''}`}>
            <li>
              <NavLink onClick={(event) => redirectHandler(event, '/vendors')}
                       end to="/vendors">Vendor List</NavLink>
            </li>
            <li>
              <NavLink onClick={(event) => redirectHandler(event, '/vendors/add')}
                       end to="/vendors/add">Add New Vendor</NavLink>
            </li>
          </ul>
        </li>
        <li>
          <div className={`left_f_list cursor_pointer ${parentIndex === 'customers' ? ' open active' : ''}`  } onClick={() => selectParentMenu('customers')}>
            <span className={'left_img'}><i className="font_16p fa fa-user"></i>  </span> <span>Customers</span>
          </div>

          <ul className={`sub_accordion first_level  ${parentIndex === 'customers' ? ' current' : ''}`}>
            <li>
              <NavLink onClick={(event) => redirectHandler(event, '/customers')}
                       end to="/customers">Customer List</NavLink>
            </li>
            <li>
              <NavLink onClick={(event) => redirectHandler(event, '/customers/add')}
                       end to="/customers/add">Add New Customer</NavLink>
            </li>
          </ul>
        </li>
        <li>
          <div className={`left_f_list no_arrow cursor_pointer ${parentIndex === 'orders' ? 'active open' : ''}`  }>
            <NavLink onClick={(event) => redirectHandler(event, '/orders')}
                     end to="/orders"> <span className={'left_img'}><i className="font_16p fas fa-dollar-sign"></i></span><span>Orders</span></NavLink>
          </div>
        </li>
        <li>
          <div className={`left_f_list no_arrow cursor_pointer ${parentIndex === 'products' ? 'active' : ''}`  }>
            <NavLink end to="/products"> <span className={'left_img'}><i className="font_16p fas fa-box-open"></i></span> <span>Products</span> </NavLink>
          </div>
        </li>
        <li>
          <div className={`left_f_list cursor_pointer ${parentIndex === 'banners' ? ' open active' : ''}`  } onClick={() => selectParentMenu('banners')}>
            <span className={'left_img'}><i className="font_16p fas fa-images"></i></span> <span>Banners</span>
          </div>

          <ul className={`sub_accordion first_level  ${parentIndex === 'banners' ? ' current' : ''}`}>
            <li><NavLink end to="/banners/1">Main Banner Desktop</NavLink></li>
            <li><NavLink end to="/banners/3">Main Banner Mobile</NavLink></li>
            <li><NavLink end to="/banners/2">Home page single banner</NavLink></li>
            <li><NavLink end to="/banners/4">App Link Banner</NavLink></li>
            <li><NavLink end to="/banners/5">Offer Banner</NavLink></li>
          </ul>
        </li>
        <li>
          <div className={`left_f_list cursor_pointer ${parentIndex === 'page-sections' ? ' open active' : ''}`  } onClick={() => selectParentMenu('page-sections')}>
            <span className={'left_img'}><i className="font_16p fas fa-bars"></i></span> <span> Page Sections</span>
          </div>

          <ul className={`sub_accordion first_level  ${parentIndex === 'page-sections' ? ' current' : ''}`}>
            <li><NavLink end to={`/page-sections`}>All Sections</NavLink></li>
            {sections && sections.data.map((section, i)=> (
              <li key={'section-'+i}><NavLink end to={`/page-sections/${section.id}`}>{section.title}</NavLink></li>
            ))}

            <li><NavLink end to={`/page-sections/create`}>+ Create New Section</NavLink></li>

          </ul>
        </li>
        <li>
          <div className={`left_f_list cursor_pointer ${parentIndex === 'pages' ? ' open active' : ''}`  } onClick={() => selectParentMenu('pages')}>
            <span className={'left_img'}><i className="font_16p fas fa-file"></i>  </span> <span>Pages</span>
          </div>

          <ul className={`sub_accordion first_level  ${parentIndex === 'pages' ? ' current' : ''}`}>
            <li><NavLink end to={`/pages`}>All Pages</NavLink></li>
            {pages && pages.data.map((page)=>(
              <li key={`page_${page.id}`}><NavLink end to={`/pages/edit/${page.id}`}>{page.title_en}</NavLink></li>
            ))}
            <li><NavLink end to={`/pages/new`}>+ Add New Page</NavLink></li>
          </ul>
        </li>
        <li>
          <div className={`left_f_list no_arrow cursor_pointer ${parentIndex === 'social-media' ? 'active' : ''}`  }>
            <NavLink end to="/social-media"> <span className={'left_img'}><i className="font_16p fas fa-share-alt"></i></span> <span>Social Media</span> </NavLink>
          </div>
        </li>
        <li>
          <div className={`left_f_list cursor_pointer ${parentIndex === 'settings' ? ' open active' : ''}`  } onClick={() => selectParentMenu('settings')}>
            <span className={'left_img'}><i className="font_16p fas fa-cogs"></i></span> <span>Settings</span>
          </div>

          <ul className={`sub_accordion first_level  ${parentIndex === 'settings' ? ' current' : ''}`}>
            <li><NavLink end to={`/settings/customer-service-information`}>Customer Service Info</NavLink></li>
            <li><NavLink end to="/settings/shipping-methods">Shipping Methods</NavLink></li>
            <li><NavLink end to="/settings/couriers">Couriers</NavLink></li>
            <li><NavLink end to="/settings/categories">categories</NavLink></li>
            <li><NavLink end to="/settings/transactions" className={'color_black'}>Transactions</NavLink></li>
          </ul>
        </li>
        <li>
          <div className={`left_f_list no_arrow cursor_pointer ${parentIndex === 'coupons' ? 'active' : ''}`  }>
            <NavLink end to="/coupons"> <span className={'left_img'}><i className="fas fa-user-secret"></i> </span> <span>Coupons</span> </NavLink>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default LeftNavComponent;