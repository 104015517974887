import Button from "../../components/UI/Button";
import {confirmAlert} from "react-confirm-alert";
import {useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {deleteShippingMethod} from "../../api/shippingMethods";
import {toast} from "react-toastify";
import {deleteSocialMedia, generateSocialMediaFeed} from "../../api/socialMedia";

const SocialMediaTableAction = ({editRecord, row}) => {
  const [toastId, setToastId] = useState(null);

  const queryClient = useQueryClient()

  const {mutate, isPending} = useMutation({
    mutationFn: deleteSocialMedia,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (response, payload) => {
      toast.update(toastId, { render: "Social Media Deleted!", type: "success", isLoading: false, autoClose: true  });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'social-media',
      });
    },
    onError: () => {
      toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
    }
  })
  const deleteHandler = () => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            mutate({id: row.id})
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        }
      ]
    });
  }

  const {mutate:generateFeeds, isPending:pendingFeeding} = useMutation({
    mutationFn: generateSocialMediaFeed,
    onMutate: () => {
      setToastId(toast.loading("Feed Generating..."))
    },
    onSuccess: (response, payload) => {
      toast.update(toastId, { render: "Social Feed Generated!", type: "success", isLoading: false, autoClose: true  });
    },
    onError: () => {
      toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
    }
  })
  const generateFeed = () => {
    generateFeeds()
  }

  return (
    <>
      {row.type === 'instagram' &&
      <Button text={'Update Feed'} isPending={pendingFeeding} onClickHandler={generateFeed}/>
      }
      <Button text={'Edit'} classes={"ml_5"}  onClickHandler={()=>editRecord(row)} />
      <Button text={'Delete'} classes={"ml_5"} variant={'btn_danger'} onClickHandler={deleteHandler} />
    </>
  )
}

export default SocialMediaTableAction