import API from "../util/api";

export const getSizes = async (params) => {
  let data = null;

  await API.get('/sizes', {params})
    .then(res => {
      data = res.data.meta ? res.data : res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const deleteSize = async (params) => {
  let data = null;

  await API.delete('/sizes/'+params.id)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const storeSize = async (payload) => {
  let data = null;

  await API.post('/sizes', payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const updateSize = async (id, payload) => {
  let data = null;

  await API.patch(`/sizes/${id}`, payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}


export const changeSizeStatus = async (payload) => {
  await API.post('/sizes/status/'+payload.id, {status: payload.status})
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return true;
}

export const changeDefaultStatus = async (payload) => {
  await API.post('/sizes/default/'+payload.id, {status: payload.status})
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return true;
}