import Modal from "../../../../components/UI/Modal";
import {useEffect, useState} from "react";
import Input from "../../../../components/UI/form/Input";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {useForm} from "react-hook-form";
import {getVendors} from "../../../../api/vendor";
import {storeMasterColor, updateMasterColor} from "../../../../api/masterColors";
import Select from "../../../../components/UI/form/Select";
import RadioBox from "../../../../components/UI/form/RadioBox";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../../../util/validationMessage";
import Button from "../../../../components/UI/Button";

const MasterColorForm = ({color = null, openModal, setOpenModal}) => {
  const {setValue, reset, register, setError, handleSubmit, formState: { errors }} = useForm({
    defaultValues: {
      "status": "1",
    },
  });

  useEffect(() => {
    reset();

    if (color) {
      Object.keys(color).forEach(key => {
        if(key === 'status'){
          setValue(key, ""+color[key]);
        } else {
          setValue(key, color[key]);
        }
      });
    }
  }, [openModal, color]);


  const queryClient = useQueryClient();
  const [toastId, setToastId] = useState(null);

  const {isPending, mutate} = useMutation({
    mutationFn: color ? (data) => updateMasterColor(color.id, data) : storeMasterColor,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      toast.update(toastId, { render: `Master Color ${color ? 'Updated' : 'Added'}`, type: "success", isLoading: false, autoClose: true });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'master-colors',
      });
      setOpenModal(false)
      reset();
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);
        errorMsg = error.message.message;
      }

      toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
    }
  });

  const onSubmit = (data) => {
    mutate(data)
  }

  const {data: vendors} = useQuery({
    queryKey:['vendors'],
    queryFn: () => getVendors()
  })


  return(
    <>
      <Modal title={`${color ? 'Edit' : 'Add'} Master Color`} open={openModal} setOpen={setOpenModal}>
        <form onSubmit={handleSubmit(onSubmit)}>

          <div className="row">
            <div className="col_6 pr_20">
              <Select
                label="Vendor"
                name="vendor_id"
                options={vendors && vendors.data.length ? vendors.data : []}
                errors={errors}
                optionValue={'id'}
                optionLabel={'name'}
                validationRules={{required: true}}
                register={register}/>
            </div>

            <div className="col_6 pr_20">
              <RadioBox label="Status"
                        name="status"
                        errors={errors}
                        optionValue={'id'}
                        optionLabel={'name'}
                        options={[{id: "1", name: 'Active'}, {id: '0', name:"Inactive"}]}
                        validationRules={{required:true, type: 'string'}}
                        register={register}/>
            </div>

            <div className="col_6 pr_20">
              <Input label="Name"
                     name="name"
                     errors={errors}
                     validationRules={{required: true, type: 'string'}}
                     register={register}/>
            </div>

            <div className="col_6 pr_20">
              <Input label="Color Code"
                     name="code"
                     type="color"
                     errors={errors}
                     validationRules={{required: true, type: 'string'}}
                     register={register}/>
            </div>

          </div>

          <div className="col_12">
            <div className="d_flex_end">
              <Button
                text="save"
                isPending={isPending}
                type={"submit"}
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default MasterColorForm;