import API from "../util/api";

export const getMasterColors = async (params) => {
  let data = null;

  await API.get('/master-colors')
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const deleteMasterColor = async (params) => {
  let data = null;

  await API.delete('/master-colors/'+params.id)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}


export const storeMasterColor = async (payload) => {
  let data = null;

  await API.post('/master-colors', payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const updateMasterColor = async (id, payload) => {
  let data = null;

  await API.patch(`/master-colors/${id}`, payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}