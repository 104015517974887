//
import API from "../util/api";

export const getSocialMedia = async (params) => {
  let data = null;

  await API.get('/social-media', {params})
    .then(res => {
      data = res.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}


export const deleteSocialMedia = async (params) => {
  let data = null;

  await API.delete('/social-media/'+params.id)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const storeSocialMedia = async (payload) => {
  let data = null;

  await API.post('/social-media', payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const updateSocialMedia = async (id, payload) => {
  let data = null;
  await API.post(`/social-media/${id}`, payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const generateSocialMediaFeed = async () => {
  let data = null;
  await API.get(`/social-feed-generate`)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}