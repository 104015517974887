import Modal from "../../components/UI/Modal";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../util/validationMessage";
import {storeSocialMedia, updateSocialMedia} from "../../api/socialMedia";
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import Input from "../../components/UI/form/Input";
import Select from "../../components/UI/form/Select";
import Button from "../../components/UI/Button";

const SocialMediaForm = ({modalStatus, setModalStatus, editItem, setEditItem}) => {

  const { reset, register, setError, setValue, handleSubmit, formState: { errors }} = useForm({
    defaultValues: {
      type: '',
    },
  });

  const medias = [
    {
      id: 'facebook',
      name: 'Facebook'
    },
    {
      id: 'instagram',
      name: 'Instagram'
    },
    {
      id: 'twitter',
      name: 'Twitter'
    },
    {
      id: 'linkedin',
      name: 'Linked In'
    },
    {
      id: 'tiktok',
      name: 'TikTok'
    },
    {
      id: 'youtube',
      name: 'Youtube'
    },
  ];
  const queryClient = useQueryClient();
  const [toastId, setToastId] = useState(null);

  const {isPending, mutate} = useMutation({
    mutationFn: editItem ? (data) => updateSocialMedia(editItem.id, data) : (data)=> storeSocialMedia(data),
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      toast.update(toastId, { render: `Social Media ${editItem ? 'Updated' : 'Added'}`, type: "success", isLoading: false, autoClose: true });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'social-media',
      });
      setModalStatus(false)
      setEditItem(null)
      reset();
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);
        errorMsg = error.message.message;
      }

      toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
    }
  });

  useEffect(()=>{
    if(editItem){
      setValue('type', editItem.type)
      setValue('url', editItem.url)
      setValue('title', editItem.title)
      setValue('access_token', editItem.access_token)
      setModalStatus(true)
    }
  }, [editItem])

  const onSubmit = (data) => {
    mutate(data)
  }

  const closeBannerModal = () => {
    reset();
    setModalStatus(false)
  }

  return (
    <Modal title={`${editItem ? 'Edit' : 'Add'} Social Media`} open={modalStatus} setOpen={closeBannerModal} size={"modal_850p"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col_12">
            <Select
              label="Type"
              name="type"
              options={medias}
              errors={errors}
              optionValue={'id'}
              optionLabel={'name'}
              validationRules={{required: true}}
              register={register}/>
          </div>
          <div className="col_12">
            <Input label="Title"
                   name="title"
                   errors={errors}
                   register={register}/>
          </div>
          <div className="col_12">
            <Input label="Access Token"
                   name="access_token"
                   errors={errors}
                   register={register}/>
          </div>
          <div className="col_12">
            <Input label="URL"
                   name="url"
                   errors={errors}
                   register={register}/>
          </div>
          <div className="col_12">
            <div className="d_flex_end">
              <Button
                text="save"
                isPending={isPending}
                type={"submit"}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default SocialMediaForm