import Select from "../../../../../components/UI/form/Select";
import RadioBox from "../../../../../components/UI/form/RadioBox";
import Input from "../../../../../components/UI/form/Input";
import {useMutation} from "@tanstack/react-query";
import {useEffect, useState} from "react";
import {getCategories} from "../../../../../api/categories";
import Checkbox from "../../../../../components/UI/form/Checkbox";
import TextEditor from "../../../../../components/UI/form/TextEditor";
import {useSelector} from "react-redux";

const InformationComponent = ({watch, setError, control, setValue, handleSubmit, errors, register, getValues}) => {
  const product = useSelector(state => state.product.currentEditProduct)

  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [subSubCategories, setSubSubCategories] = useState([])

  const {mutate:categoryMutate} = useMutation({
    mutationFn: getCategories,
    onSuccess: (response, payload) => {
      setCategories(response)
    },
  })


  useEffect(()=>{
    if(!categories.length){
      categoryMutate()
    }
  }, [categories])

  useEffect(()=>{
    if(categories) {
      let subcategories = categories.find(cat => +cat.id === +getValues('parent_category_id'))?.sub_categories
      setSubCategories(subcategories)
    }
  }, [watch('parent_category_id')])

  useEffect(()=>{
    if(subCategories && subCategories.length) {
      let subcategories = subCategories.find(cat => +cat.id === +getValues('second_category_id'))?.sub_categories
      setSubSubCategories(subcategories)
    }
  }, [watch('second_category_id')])

  useEffect(()=>{
    if(product && categories.length){
      setTimeout(()=>{
        setValue("parent_category_id", +product.parent_category_id)
      }, 100)
      setTimeout(()=>{
        setValue("second_category_id", product.second_category_id)
      }, 200)
      setTimeout(()=>{
        setValue("third_category_id", product.third_category_id)
      }, 300)
      setValue("is_physical", product.is_physical ? true : false)
      setValue("tracking_inventory", product.tracking_inventory ? true : false)
      setValue("sell_out_of_stock", product.sell_out_of_stock ? true : false)
    }
  }, [product, categories])






  return(
    <>
      <div className={"row"}>
        <div className="col_6 pr_20">
          <div className="row">
            <div className="col_6 pr_20">
              <RadioBox label="Status"
                        name="status"
                        errors={errors}
                        options={[{id: "1", name: 'Active'}, {id: "0", name:"Inactive"}]}
                        validationRules={{required:true, type: 'string'}}
                        register={register}/>
            </div>
            <div className="col_12 pr_20">
              <Input label="SKU"
                     name="sku"
                     errors={errors}
                     validationRules={{required: true, type: 'string'}}
                     register={register}/>
            </div>

            <div className="col_6 pr_20">
              <Input label="Price."
                     name="price"
                     errors={errors}
                     type={"double"}
                     validationRules={{required: true}}
                     register={register}/>
            </div>
            <div className="col_6 pr_20">
              <Input label="Orig. Price"
                     name="original_price"
                     errors={errors}
                     type={"double"}
                     validationRules={{required: false}}
                     register={register}/>
            </div>



            <div className="col_12 pr_20">
              <TextEditor
                label="Description"
                name="description"
                placeholder={"Max. 500 letters allowed. The following special characters are not allowed: <, >, {, }, ^, [, ], =, @, ;"}
                errors={errors}
                validationRules={{type: 'string'}}
                data={ getValues('description')}
                onchangeHandler={(data) => setValue('description', data)}
                register={register}
              />
            </div>

          </div>
        </div>

        {/*second column*/}

        <div className="col_6 pr_20">
          <div className="row">
            <div className="col_12 pr_20">
              <Input label="Name"
                     name="name"
                     errors={errors}
                     validationRules={{required: true, type: 'string'}}
                     register={register}/>
            </div>
            <div className="col_12">
              <div className="form_row">
                <label className={'required'}>Category</label>
              </div>
            </div>


            <div className="col_12">
              <div className="row p_relative">
                <div className="col_4 pr_20">
                  <Select
                    name={`parent_category_id`}
                    options={categories}
                    errors={errors}
                    validationRules={{required: true}}
                    register={register} />
                </div>

                <div className="col_4 pr_20">
                  <Select
                    name={`second_category_id`}
                    options={subCategories}
                    errors={errors}
                    validationRules={{required: false}}
                    register={register} />
                </div>

                <div className="col_4 pr_20">
                  <Select
                    name={`third_category_id`}
                    options={subSubCategories}
                    errors={errors}
                    validationRules={{required: false}}
                    register={register} />
                </div>
              </div>
            </div>


            <div className="col_6 pr_20">
              <Input label="Weight"
                     name="weight"
                     errors={errors}
                     validationRules={{type: 'double'}}
                     register={register}/>
            </div>

            <div className="col_6 pr_20">
              <Select
                label={"Weight Unit"}
                name={`weight_unit`}
                options={[{id: "kg", name: "KG"}, {id:"lb", name: "LB"}, {id: "oz", name: "OZ"}, {id:"g", name: "G"}]}
                errors={errors}
                validationRules={{required: false}}
                register={register} />
            </div>




            <div className="col_12 pr_20">
              <Input label="Available Quantity"
                     name="available_quantity"
                     errors={errors}
                     validationRules={{type: 'number'}}
                     register={register}/>
            </div>
            <div className="col_6 ">
              <Checkbox name={`is_physical`}
                        label={"Is Physical"}
                        classes="mb_0 pt_15"
                        register={register}
                        checked={getValues('is_physical') ? 1 : 0} />
            </div>
            <div className="col_6 ">
              <Checkbox name={`tracking_inventory`}
                        label={"Tracking Inventory"}
                        classes="mb_0 pt_15"
                        register={register}
                        checked={getValues('tracking_inventory') ? 1 : 0} />

            </div>

            <div className="col_6 ">
              <Checkbox name={`sell_out_of_stock`}
                        label={"Sell out of stock"}
                        classes="mb_0 pt_15"
                        register={register}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InformationComponent