import {getPageSection} from "../../api/pageSections";
import {useLoaderData, useParams} from "react-router-dom";
import PageSectionForm from "../../views/page-sections/PageSectionForm";
import Card from "../../components/UI/Card";
import PageSectionCardButton from "../../views/page-sections/PageSectionCardButton";
import {useEffect, useState} from "react";
import SectionSummaryComponent from "../../views/page-sections/SectionSummaryComponent";
import SectionProductsComponent from "../../views/page-sections/SectionProductsComponent";
import AllProductsComponent from "../../views/page-sections/AllProductsComponent";
import RemoveProductComponent from "../../views/page-sections/RemoveProductComponent";

export async function sectionLoader({ params }) {
  const sectionData = params.id ? await getPageSection(params.id) : undefined
  return {sectionData}
}
const PageSectionPage = () => {
  const params = useParams()
  const {sectionData} = useLoaderData();
  const [modalStatus, setModalStatus] = useState(false)
  const [addProduct, setProduct] = useState(false)
  const [products, setProducts] = useState([])
  const [section, setSection] = useState(null)


  useEffect(()=> {
    if (sectionData){
      setSection(sectionData)

      if (sectionData.products.length) {
        setProducts([...sectionData.products])
      } else {
        setProducts([])
      }
    } else {
      setSection(null)
      setProducts([])
    }
  }, [sectionData])

  useEffect(()=> {
    setModalStatus(false)
    setProduct(false)
  }, [params])

  const openModal = () => {
    setModalStatus(true)
  }

const addNewProduct = (product) => {
    let ids = [];
    product.map((item)=>{
      ids.push(item.id)
    })
  setSection((section)=> ({...section, product_ids: ids}))
  setProducts(product)
}



  return (
    <>
      <Card title={`${section ? section.title : 'Section'}`} CardActions={<PageSectionCardButton section={section} openAllProducts={setProduct} clickHandler={openModal} />}>
        {(section && (section.title || section.description)) ? <SectionSummaryComponent section={section} /> : ''}
      </Card>
      {products.length ?
        <Card title={"Section's product"} CardActions={<RemoveProductComponent addProductButton={addProduct} section={section} removeProducts={addNewProduct} />}>
          <SectionProductsComponent products={products}/>
        </Card> : ''
      }
      {addProduct ?
          <AllProductsComponent section={section} products={products} setProducts={addNewProduct} modalStatus={addProduct} setModalStatus={setProduct}/>
        :
        ''
      }
      {modalStatus && <PageSectionForm products={products} section={section} modalStatus={modalStatus} setModalStatus={setModalStatus} setSection={setSection} />}
      <br/>
      <br/>
      <br/>
    </>
  );
}

export default PageSectionPage