import {useForm} from "react-hook-form";
import {useMutation} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../../util/validationMessage";
import {useState} from "react";
import Input from "../../../components/UI/form/Input";
import Button from "../../../components/UI/Button";
import { updateCustomerServiceInformation} from "../../../api/customerInformation";



const CustomerInformationForm = ({customerService}) => {

  const {setValue, reset, register, setError, handleSubmit, formState: { errors }} = useForm(
    {
      defaultValues:{
        customer_service_number: customerService ? customerService.customer_service_number : '',
        customer_service_time: customerService ? customerService.customer_service_time : '',
        customer_service_email: customerService ? customerService.customer_service_email : '',
        phone_service_number: customerService ? customerService.phone_service_number : '',
        phone_service_time: customerService ? customerService.phone_service_time : '',
        accessibility: customerService ? customerService.accessibility : '',
        accessibility_link: customerService ? customerService.accessibility_link : '',
      }
    }
  );
  const [toastId, setToastId] = useState(null);

  const {isPending, mutate} = useMutation({
    mutationFn: updateCustomerServiceInformation,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      toast.update(toastId, { render: `Information updated`, type: "success", isLoading: false, autoClose: true });

      reset();
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);
        errorMsg = error.message.message;
      }

      toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
    }
  });

  const onSubmit = (data) => {
    mutate(data)
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col_6">
            <Input label="Customer Service Number"
                   name="customer_service_number"
                   errors={errors}
                   validationRules={[]}
                   register={register}/>
          </div>
          <div className="col_6">
            <Input label="Customer Service Time"
                   name="customer_service_time"
                   errors={errors}
                   validationRules={[]}
                   register={register}/>
          </div>
          <div className="col_6">
            <Input label="Customer Service Email"
                   name="customer_service_email"
                   errors={errors}
                   validationRules={[]}
                   register={register}/>
          </div>
          <div className="col_6">
            <Input label="Phone Service Number"
                   name="phone_service_number"
                   errors={errors}
                   validationRules={[]}
                   register={register}/>
          </div>
          <div className="col_6">
            <Input label="Phone Service Time"
                   name="phone_service_time"
                   errors={errors}
                   validationRules={[]}
                   register={register}/>
          </div>

        </div>
        <div className="col_12 mb_10">
          <div className="d_flex_end">
            <Button
              text="save"
              isPending={isPending}
              type={"submit"}
            />
          </div>
        </div>
      </form>
    </>
  );
}

export default CustomerInformationForm