import {useEffect, useState} from "react";
import CategoryActionComponent from "./CategoryActionComponent";
import CategoryArrowComponent from "./CategoryArrowComponent";
import {ReactSortable} from "react-sortablejs";

const SubCategoryListComponent = ({subCategory, editHandler, deleteHandler}) => {
  const [isExpended, setIsExpanded] = useState(false);
  const [subSubCategories, setSubSubCategories] = useState(subCategory.sub_categories || []);

  useEffect(() => {
    setSubSubCategories(subCategory.sub_categories || []);
  }, [subCategory]);

  return (
    <div className={"d_flex_inline width_full sub_category_list_inner "} key={'second1_' + subCategory.slug}>
      {(subSubCategories.length > 0) &&
        <CategoryArrowComponent isExpended={isExpended}
                                setIsExpanded={setIsExpanded} />
      }

      <div className="item_color_text mt_10 pb_10" key={'second_' + subCategory.slug}>
        <CategoryActionComponent editHandler={editHandler}
                                 category={subCategory}
                                 deleteHandler={deleteHandler} />

        {(subSubCategories.length > 0 && isExpended) &&
          <ReactSortable animation={200}
                         list={subSubCategories}
                         setList={setSubSubCategories}
                         className="sub_category_list_outer third_level">
            {subSubCategories.map(item => (
              <div className="item_color_text mt_10 pb_10" key={'third_' + item.slug}>
                <CategoryActionComponent editHandler={editHandler}
                                         category={item}
                                         key={`sub_sub_${item.id}`}
                                         deleteHandler={deleteHandler}/>
              </div>
            ))}
          </ReactSortable>
        }
      </div>
    </div>
  );
};

export default SubCategoryListComponent;