import Input from "../../../components/UI/form/Input";
import Button from "../../../components/UI/Button";
import Modal from "../../../components/UI/Modal";
import {useForm} from "react-hook-form";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useEffect, useState} from "react";
import {addBanners, updateBanners} from "../../../api/banner";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../../util/validationMessage";
import {useDispatch, useSelector} from "react-redux";
import {setEditBanner} from "../../../store/slices/bannerSlice";
import RadioBox from "../../../components/UI/form/RadioBox";
import {useParams} from "react-router-dom";

const BannerForm = ({modalStatus, setModalStatus, showTextField}) => {
  const {type} = useParams()
  const { reset, register, setError, setValue, handleSubmit, formState: { errors }} = useForm({
    defaultValues: {
      type: type,
      status: "1",
    },
  });
  const {editBanner} = useSelector(state => state.banners)
  const dispatch = useDispatch();
  const [tempShow, setTempFileShow] = useState(null)
  const queryClient = useQueryClient();
  const [toastId, setToastId] = useState(null);


  useEffect(()=>{
    if(editBanner){
      setValue('url', editBanner.url)
      setValue('status', editBanner.status+"")
      setValue('title', editBanner.title)
      setValue('description', editBanner.description)
      setModalStatus(true)
    }
  }, [editBanner])


  const {isPending, mutate} = useMutation({
    mutationFn: editBanner ? (data) => updateBanners(editBanner.id, data) : (data)=> addBanners(data),
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      toast.update(toastId, { render: `Banner ${editBanner ? 'Updated' : 'Added'}`, type: "success", isLoading: false, autoClose: true });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === `banners-${type}`,
      });
      setModalStatus(false)
      reset();
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);
        errorMsg = error.message.message;
      }

      toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
    }
  });

  const onSubmit = (data) => {
    const file = data.media[0];
    let formData = new FormData()
    Object.keys(data).forEach(key => {
      formData.append(key, data[key] ? data[key] : '');
    })

    formData.append('type', type)
    if(file)
      formData.append('media', file)

    mutate(formData)
  }

  const closeBannerModal = () => {
    reset();
    dispatch(setEditBanner(null));
    setModalStatus(false)
  }
  return (
    <>
      <Modal title={`${editBanner ? 'Edit' : 'Add'} Banner`} open={modalStatus} setOpen={closeBannerModal} size={"modal_600p"}>
        <form onSubmit={handleSubmit(onSubmit)}>

          <div className="row">
            <div className="col_12 pr_20">
              <RadioBox label="Status"
                        name="status"
                        errors={errors}
                        options={[{id: "1", name: 'Active'}, {id: "0", name:"Inactive"}]}
                        validationRules={{required:true, type: 'string'}}
                        register={register}/>
            </div>
            <div className="col_12">
              <span className={"p_absolute r_10 color_danger"}>Recommended e.g: (965X450)</span>
                <Input label="media"
                       name="media"
                       errors={errors}
                       type={"file"}
                       validationRules={ editBanner ? {required: false } :  {required: true } }
                       register={register}/>
            </div>

            {tempShow &&
            <div className="col_12">
              <img src={tempShow} width={100} alt=""/>
            </div>
            }
            {showTextField && <>
            <div className="col_12 pr_20">
              <Input label="Title"
                     name="title"
                     errors={errors}
                     register={register}/>
            </div>

            <div className="col_12 pr_20">
              <Input label="Description"
                     name="description"
                     errors={errors}
                     register={register}/>
            </div>
            </>}

            <div className="col_12 pr_20">
              <Input label="URL"
                     name="url"
                     errors={errors}
                     register={register}/>
            </div>

            <div className="col_12">
              <div className="d_flex_end">
                <Button
                  text="save"
                  isPending={isPending}
                  type={"submit"}
                />
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default BannerForm;