const SectionSummaryComponent = ({section}) => {
  return(
    <div className="row">
      <div className="col_12">
        <p><b>Section Title:</b> {section.title}</p>
        <p><b>Section Slug:</b> {section.slug}</p>
        <p><b>Section Desctiption:</b> <span dangerouslySetInnerHTML={{ __html: section.description }}></span></p>
        {section.link && <p><b>Link:</b> {section.link}</p>}
        {section.sort && <p><b>Sort:</b> {section.sort}</p>}
      </div>
    </div>
  );
}

export default SectionSummaryComponent