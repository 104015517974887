import {confirmAlert} from "react-confirm-alert";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {useState} from "react";
import {deleteMasterColor} from "../../../../api/masterColors";

const MasterColorItem = ({color,handleClick}) => {
const queryClient = useQueryClient()
  const [toastId, setToastId] = useState(null);

  const {mutate} = useMutation({
    mutationFn: deleteMasterColor,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (response, payload) => {
      toast.update(toastId, { render: "Master Color Deleted!", type: "success", isLoading: false, autoClose: true  });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'master-colors',
      });
    },
    onError: () => {
      toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
    }
  })
  const edititem = () => {
    handleClick(color.id)
  }
  const deleteItem = () => {
    confirmAlert({
      title: "Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            mutate({id: color.id})
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        }
      ]
    })
  }

  return (
    <>
      {color &&
      <div className="item_color_inner">
        <div className="item_color_inner_wrap">
          <div className="image_div" style={{backgroundColor: color.code ? color.code : color.code }}></div>
          <div className="item_color_text">
            <p>{color.name}</p>
            <div className="d_flex_btwn">
              <span className="color_primary cursor_pointer mr_5"  onClick={edititem}>Edit</span> |
              <span onClick={deleteItem} className="color_danger cursor_pointer ml_5">Delete</span>
            </div>
          </div>
        </div>
      </div>
      }
    </>
  )
}

export default MasterColorItem;