import Button from "../../../../components/UI/Button";

const CategoryListActions = ({clickHandler}) =>{
  return (
    <Button
      text="+ Add New Category"
      variant={"btn_primary"}
      onClickHandler={clickHandler}
    />
  );
}
export default CategoryListActions;