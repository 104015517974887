import {createSlice} from "@reduxjs/toolkit";

export const bannerSlice = createSlice({
  name: 'banners',
  initialState:{
    editBanner: null,
  },
  reducers:{
    setEditBanner:(state, action)=>{
      state.editBanner = action.payload
    }
  }
});

export const {
  setEditBanner
} = bannerSlice.actions

export default bannerSlice.reducer;