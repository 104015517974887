import {confirmAlert,} from "react-confirm-alert";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {useState} from "react";
import ReactiveButton from "reactive-button";
import {deleteSize} from "../../../../api/sizes";
import Button from "../../../../components/UI/Button";

const SizeTableActions = ({row, editSize}) =>{

  const [toastId, setToastId] = useState(null);

  const queryClient = useQueryClient()

  const {mutate, isPending} = useMutation({
    mutationFn: deleteSize,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (response, payload) => {
      toast.update(toastId, { render: "Size Deleted!", type: "success", isLoading: false, autoClose: true  });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'sizes',
      });
    },
    onError: () => {
      toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
    }
  })

  const editHandler = ()=>{
    editSize(row)
  }

  const deleteHandler = () => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            mutate({id: row.id})
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        }
      ]
    });
  }
  return (
    <>
      <Button
        text="Edit"
        classes={"mr_5"}
        variant={"btn_warning"}
        onClickHandler={editHandler}
      />
      <Button
        text="Delete"
        variant={"btn_danger"}
        isPending={isPending}
        onClickHandler={deleteHandler}
      />
    </>
  );
}

export default SizeTableActions;