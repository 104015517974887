import {getSocialMedia} from "../../api/socialMedia";
import SocialFormButton from "./SocialFormButton";
import Card from "../../components/UI/Card";
import {createColumnHelper} from "@tanstack/react-table";
import Table from "../../components/UI/Table";
import SocialMediaTableAction from "./SocialMediaTableAction";

const SocialMediaList = ({setModalStatus, setEditItem}) => {
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('title', {
      id: 'title',
      header: 'Title'
    }),
    columnHelper.display( {
      id: 'type',
      header: 'Type',
      cell: props => <span className={'w_b_label'}>{props.row.original.type}</span>
    }),
    columnHelper.accessor('url', {
      id: 'url',
      header: 'URL'
    }),

    columnHelper.display( {
      id: 'actions',
      header: "Actions",
      classes:'text_right',
      cell: props => <SocialMediaTableAction editRecord={setEditItem} row={props.row.original} />
    }),
  ];

  return (
    <Card title={"Social Media"} CardActions={<SocialFormButton openModal={setModalStatus} /> }>
      <Table
        queryKey={"social-media"}
        columns={columns}
        queryParams={{per_page: 10}}
        queryFn={getSocialMedia}
      />
    </Card>
  )
}

export default SocialMediaList