import Card from "../../../components/UI/Card";
import CustomerInformationForm from "../../../views/settings/customer-info/CustomerInformationForm";
import {getCustomerServiceData} from "../../../api/customerInformation";
import {useLoaderData} from "react-router-dom";
export async function preloadCustomerService(){
  const [serviceinfo] = await Promise.all([
    getCustomerServiceData()
  ]);
  return {serviceinfo};
}
const CustomerInformation = () => {
  let customerService = null;
  const loaderData = useLoaderData();
  if(loaderData)
    customerService = loaderData.serviceinfo

  return (
    <Card title={"Customer Service Information"}>
      <CustomerInformationForm customerService={customerService} />
    </Card>
  )
}

export default CustomerInformation