import API from "../util/api";

export const getMadeIn = async (params) => {
  let data = null;

  await API.get('/made-ins', {params})
    .then(res => {
      data = res.data.meta ? res.data : res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const deleteMadeIn = async (params) => {
  let data = null;

  await API.delete('/made-ins/'+params.id)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}


export const storeMadeIn = async (payload) => {
  let data = null;

  await API.post('/made-ins', payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const updateMadeIn = async (id, payload) => {
  let data = null;

  await API.patch(`/made-ins/${id}`, payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const changeMadeInStatus = async (payload) => {
  await API.post('/made-ins/status/'+payload.id, {status: payload.status})
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return true;
}

export const changeMadeInDefaultStatus = async (payload) => {
  await API.post('/made-ins/default/'+payload.id, {status: payload.status})
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return true;
}