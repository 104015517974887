import Button from "../../../../components/UI/Button";

const ProductFormHeadingButtonGroup = ({cloneClickHandler, nextPrevItemClickHandler})=> {
  return (
    <div className={"btn_grp"}>
      {/*<Button*/}
      {/*  text="Clone"*/}
      {/*  classes={"mr_10"}*/}
      {/*  variant={"btn_info"}*/}
      {/*  onClickHandler={cloneClickHandler}*/}
      {/*/>*/}
      {/*<Button*/}
      {/*  text="< Previous Item"*/}
      {/*  variant={"btn_info"}*/}
      {/*  classes={"mr_10"}*/}
      {/*  onClickHandler={()=>{nextPrevItemClickHandler('prev')}}*/}
      {/*/>*/}
      {/*<Button*/}
      {/*  text="Next Item >"*/}
      {/*  variant={"btn_info"}*/}
      {/*  onClickHandler={()=>{nextPrevItemClickHandler('next')}}*/}
      {/*/>*/}
    </div>
  );
}

export default ProductFormHeadingButtonGroup