import Button from "../../components/UI/Button";
import {useMutation} from "@tanstack/react-query";
import {removeSectionsProducts} from "../../api/pageSections";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {toast} from "react-toastify";
import {setSelectToggle} from "../../store/slices/productSlice";
const RemoveProductComponent = ({addProductButton, section, removeProducts}) => {
  const [toastId, setToastId] = useState(null);
  const store = useSelector(state => state.product);
  const dispatch = useDispatch()
  const {isPending, mutate} = useMutation({
    mutationFn:  (data) => removeSectionsProducts(section? section.id:'', data),
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      toast.update(toastId, { render: `Section product updates`, type: "success", isLoading: false, autoClose: true });
      removeProducts(res)
      dispatch(setSelectToggle({type: 'unselect'}))
    },
    onError: () => {
      toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
    }
  });
  const {selectedProducts} = useSelector(state => state.product);
  const handlerClickEvent = () => {
    if(selectedProducts.length) {
      var res = section.product_ids.filter( function(n) { return !this.has(+n) }, new Set(selectedProducts) );
      mutate({ids:res});
    }
  }

  return (
    <>
      {store.selectedProducts.length && !addProductButton ?
      <Button
        text={"Remove Product"}
        variant={"btn_danger"}
        isPending={isPending}
        onClickHandler={handlerClickEvent}
      /> : ''
      }
    </>
  );
}

export default RemoveProductComponent