import Button from "../../components/UI/Button";
import {Link} from "react-router-dom";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import {useState} from "react";
import {deleteCoupon} from "../../api/coupon";

const CouponListAction = ({row})=>{

    const queryClient = useQueryClient()
    const [toastId, setToastId] = useState(null);

    const {mutate, isPending} = useMutation({
        mutationFn: deleteCoupon,
        onMutate: () => {
            setToastId(toast.loading("Please wait..."))
        },
        onSuccess: (response, payload) => {
            toast.update(toastId, { render: "Coupon Has been Deleted!", type: "success", isLoading: false, autoClose: true  });
            queryClient.invalidateQueries({
                predicate: (query) => query.queryKey[0] === 'coupons',
            });
        },
        onError: () => {
            toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
        }
    })
    const deleteHandler = () => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        mutate({id: row.id})
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    },
                }
            ]
        });
    }

    return (
        <>
            <Link  to={"/coupons/"+row.id} className="mr_5">
                <Button text={'Details'} variant={'btn_info'} />
            </Link>
            <Button text={'Delete'} classes={"ml_5"} variant={'btn_danger'} isPending={isPending} onClickHandler={deleteHandler} />
        </>
    )
}

export default CouponListAction