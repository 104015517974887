import API from "../util/api";
export const updateCustomerServiceInformation = async (payload) => {
  let data = null;
  await API.post(`/update-customer-info`, payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const getCustomerServiceData = async () => {
  let data = null;
  await API.get(`/update-customer-info`)
    .then(res => {
      data = res.data.data;
      return data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
