import Card from "../../../components/UI/Card";
import BannerMediaItemButtons from "./BannerMediaItemButtons";

const BannerMediaItem = ({banner}) => {
  return (
    <>
      <div className="col_2">
        <Card>

          {banner.media_type === 'image' &&
          <img src={banner.media} className={"width_full"} alt=""/>
          }
          {banner.media_type === 'video' && (<div>
          <video className={"width_full"} controls>
            <source src={banner.media} type="video/mp4" />
              <source src={banner.media} type="video/ogg" />
          </video>
          </div>)
          }
          <BannerMediaItemButtons banner={banner} />
        </Card>
      </div>

    </>
  );
}

export default BannerMediaItem